<div class="col mt-3 mt-md-0">
  <div class="filter-dropdowns {{ disable ? 'disabled' : '' }}">
    <ngx-bootstrap-multiselect
      [options]="optionList"
      [settings]="mySettings"
      [texts]="myTexts"
      [(ngModel)]="optionsModel"
      (ngModelChange)="onChange()"
      container="body"
      [disabled]="disable"
    ></ngx-bootstrap-multiselect>
  </div>
</div>
