import { CredentialsService } from '@app/auth';
import { Credentials } from './auth/credentials.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HelperService, SharedChangePasswordComponent } from '@shared';
import { Logger } from './@core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private credentials: CredentialsService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    const onNavigationEnd = this.router.events.pipe(filter((event) => event instanceof NavigationEnd));
    const onNavigationStart = this.router.events.pipe(filter((event) => event instanceof NavigationStart));

    onNavigationStart.pipe(untilDestroyed(this)).subscribe(() => {
      this.helperService.addRouterInfo(null);
    });
    // Change page title on navigation or language change, based on route data
    onNavigationEnd
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        switchMap((route) => {
          return route.data;
        }),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        if (this.credentials?.credentials?.resetPassword) {
          this.openChangePasswordModal();
        }
        const title = event.title;
        if (title) {
          this.titleService.setTitle(title + ' - In2linx');
        }
        window.scrollTo(0, 0);
      });
  }
  openChangePasswordModal() {
    const ref = this.modalService.open(SharedChangePasswordComponent, {
      centered: true,
      size: 'lg',
      keyboard: false,
      backdrop: 'static',
    });
    ref.componentInstance.resetPassword = true;
  }
  ngOnDestroy() {}
}
