import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@app/@core';

import { CredentialsService } from './credentials.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.isAuthenticated()) {
      return true;
    }

    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/login'], { queryParams: { redirect: state.url }, replaceUrl: true });
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AdminRoleGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log(route);
    if (this.credentialsService.isAdmin) {
      return true;
    }
    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/dashboard']);
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrgAdminRoleGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (
      this.credentialsService.roleName == 'Head of Organization' ||
      this.credentialsService.roleName == 'Administrator'
    ) {
      return true;
    }
    log.debug('Not authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/dashboard']);
    return false;
  }
}
