import { Injectable } from '@angular/core';
import { User } from '@app/@shared';
import * as _ from 'lodash';
export interface Credentials extends User {
  resetPassword?: Boolean;
  contact?: any;
  admin: boolean;
  customInfo?: any;
  logo?: any;
}

const credentialsKey = 'credentials';
const seccredentialsKey = 'seccredentials';
const switchas = 'switchas';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private _credentials: Credentials | null = null;
  private _secCredentials: Credentials | null = null;
  private _data: any = {};
  private _switchas: string = null;

  constructor() {
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    const secSavedCredentials = sessionStorage.getItem(seccredentialsKey) || localStorage.getItem(seccredentialsKey);

    if (secSavedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
      this._secCredentials = JSON.parse(secSavedCredentials);
      this._switchas = sessionStorage.getItem(switchas) || localStorage.getItem(switchas);
    } else {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials;
  }
  get isAdmin(): boolean {
    return this.credentials?.admin;
  }
  get fullName(): string {
    return `${this.credentials?.firstName ?? ''} ${this.credentials?.lastName ?? ''}`?.trim();
  }
  get userId(): number {
    return this.credentials?.id;
  }
  get roleId(): number {
    return this.credentials?.memberships?.[0]?.roleId;
  }
  get roleName(): string {
    return this.credentials?.memberships?.[0]?.roleName;
  }
  get customInfo(): any {
    return this.credentials?.customInfo;
  }

  get logo(): string {
    return this.credentials?.logo;
  }

  get orgId(): string {
    var orgId = null;
    this.credentials?.customInfo?.map((item) => {
      if (item?.definition.name == 'organizationID') orgId = item.value;
    });
    return orgId;
  }
  get roles(): number[] {
    var list = [];
    this.credentials?.memberships?.map((item) => {
      list.push(item.roleId);
    });
    return list;
  }
  get groups(): number[] {
    var list = [];
    this.credentials?.memberships?.map((item) => {
      list.push(item.groupId);
    });
    return list;
  }
  get isMaskModeOn(): boolean {
    return this._secCredentials !== null && this._secCredentials !== undefined;
  }
  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }
  get secCredentials(): Credentials | null {
    return this._secCredentials;
  }

  set data(attr: string) {
    this._data[attr] = attr;
  }

  get data(): any {
    return this._data;
  }

  resetData() {
    this._data = {};
  }

  get switchas(): string {
    return this._switchas;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }
  setSecCredentials(credentials?: Credentials, remember?: boolean) {
    this._secCredentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(seccredentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(seccredentialsKey);
      localStorage.removeItem(seccredentialsKey);
    }
  }

  setSwitchas(switchasRoleOrUser?: string, remember?: boolean) {
    this._switchas = switchasRoleOrUser;

    if (switchasRoleOrUser) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(switchas, switchasRoleOrUser);
    } else {
      sessionStorage.removeItem(switchas);
      localStorage.removeItem(switchas);
    }
  }
}
