import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminRoleGuard, OrgAdminRoleGuard } from '@app/auth';
import { Shell } from './shell/shell.service';
const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    {
      path: 'dashboard',
      loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    },
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    },
    {
      path: 'external-app',
      canActivate: [OrgAdminRoleGuard],
      loadChildren: () => import('./external-application/external-app-config.module').then((m) => m.ExternalAppConfig),
    },
    {
      path: 'schedulers',
      loadChildren: () => import('./schedulers/schedulers-app-config.module').then((m) => m.SchedulerAppConfig),
    },
    {
      path: 'settings',
      canActivate: [AdminRoleGuard],
      loadChildren: () => import('./settings/settings.module').then((m) => m.SettingAppConfig),
    },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PagesRoutingModule {}
