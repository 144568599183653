import { ScopeFilter } from './../@core/scope-filter';
import { LoginControllerService } from './../@core/api/notes-module/services/login-controller.service';
import { Credentials } from '@app/auth';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CredentialsService } from './credentials.service';
import { environment } from '@env/environment';
export interface LoginContext {
  userName: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private credentialsService: CredentialsService,
    private loginControllerService: LoginControllerService,
    private httpClient: HttpClient
  ) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<boolean> {
    let body = new URLSearchParams();
    body.set('username', context.userName.trim());
    body.set('password', context.password);
    return this.httpClient
      .post('/bonita/loginservice', body?.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
        observe: 'response',
        responseType: 'text',
      })
      .pipe(
        map((result) => {
          return true;
        })
      );
  }
  getUserProfile(): Observable<Credentials> {
    return this.httpClient.get<Credentials>('/bonita/API/extension/userProfile');
  }
  verifyPassword(context: LoginContext): Observable<boolean> {
    let body = new URLSearchParams();
    body.set('username', context.userName.trim());
    body.set('password', context.password);
    return this.httpClient
      .post('/bonita/loginservice', body?.toString(), {
        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'),
      })
      .pipe(
        map((result: boolean) => true),
        catchError((err) => of(false))
      );
  }
  checkLogin(userId: number) {
    const filter: ScopeFilter = {
      'filter[where][userId]': userId?.toString(),
    };
    return of(true);/*
    return this.loginControllerService.find({ filter }).pipe(
      map((result) => {
        if (result?.length > 0) {
          return true;
        } else {
          return false;
        }
      })
    );*/
  }
  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setCredentials();
    this.credentialsService.setSecCredentials();
    this.credentialsService.setSwitchas();
    this.credentialsService.resetData();
    return of(true);
  }
}
