import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, map, tap, share } from 'rxjs/operators';
import { shareReplay, first, filter } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { HotToastService } from '@ngneat/hot-toast';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root',
})
export class CacheInterceptor implements HttpInterceptor {
  private cache: Map<String, HttpResponse<any>> = new Map();
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var headers = req.headers;
    var needCache = false;
    headers.keys().forEach((key) => {
      if (key == 'cust-cache') needCache = true;
    });

    if (!needCache && (req.method !== 'GET' || req.url.indexOf('/bonita/API/identity') == -1)) {
      return next.handle(req);
    }
    if (req.headers.get('reset')) {
      this.cache.delete(req.url);
    }

    // Check if observable is in cache, otherwise call next.handle
    const cachedObservable =
      this.cache[req.url] ||
      (this.cache[req.url] = next.handle(req).pipe(
        // Filter since we are interested in caching the response only, not progress events
        filter((res) => res instanceof HttpResponse),
        // Share replay will cache the response
        shareReplay(1)
      ));
    // pipe first() to cause the observable to complete after it emits the response
    // This mimics the behaviour of Observables returned by Angular's httpClient.get()
    // And also makes toPromise work since toPromise will wait until the observable completes.
    return cachedObservable.pipe(first());
  }
}
