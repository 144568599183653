<div class="set-url-modal">
  <div class="heading d-flex align-items-center justify-content-between">
    <span>{{ title }}</span>
    <i class="fas fa-times" (click)="activeModal.dismiss()"></i>
  </div>

  <div class="set-url-modal-content">
    <form action="" [formGroup]="form" *ngIf="isForm && !loading && form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label required">Protocol</label>
          <app-shared-multi-dropdown
            [optionList]="protocol"
            [title]="'Select Protocol'"
            [selectionLimit]="1"
            [autoUnselect]="true"
            [showUncheckAll]="true"
            [showCheckAll]="false"
            [enableSearch]="true"
            [closeOnSelect]="true"
            [dynamicTitleMaxItems]="1"
            [optionsModel]="form.get('protocol').value"
            (changeOut)="this.form.get('protocol')?.setValue($event); form.markAsDirty()"
          ></app-shared-multi-dropdown>
          <app-shared-form-error [control]="form.get('protocol')" [name]="'Protocol'"> </app-shared-form-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label required">Server</label>
          <input formControlName="server" placeholder="Enter Server Details" type="text" required="required" />
          <app-shared-form-error [control]="form.get('server')" [name]="'Server'"> </app-shared-form-error>
        </div>
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label required">Port</label>
          <input
            formControlName="port"
            placeholder="Enter Port Details"
            type="text"
            pattern="[0-9]+"
            required="required"
          />
          <app-shared-form-error [control]="form.get('port')" [name]="'Port'"> </app-shared-form-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label required">Username</label>
          <input formControlName="username" placeholder="Enter Username Details" type="text" required="required" />
          <app-shared-form-error [control]="form.get('username')" [name]="'Username'"> </app-shared-form-error>
        </div>

        <div class="col-md-6">
          <label class="control-label pe-2 popup-label required">Password</label>
          <input formControlName="password" placeholder="Enter Password Details" type="password" required="required" />
          <app-shared-form-error [control]="form.get('password')" [name]="'Password'"> </app-shared-form-error>
        </div>
      </div>
    </form>

    <div class="buttons-container d-flex align-items-center justify-content-end">
      <a *ngIf="closeButtons" style="padding: 7px" class="link" (click)="activeModal.dismiss()">{{
        closeButtons ?? 'Cancel'
      }}</a>
      <a *ngIf="this.data?.persistenceId && !disableForm" class="link p-3" (click)="deleteSetting()"
        >Delete Configuration</a
      >
      <button
        *ngIf="successButtons"
        class="btn btn-primary"
        (click)="onSubmit()"
        [disabled]="isForm && !isAnyFieldFilled"
      >
        {{ successButtons ?? 'Ok' }}
      </button>
    </div>
  </div>
</div>
