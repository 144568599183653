<div class="set-url-modal">
  <div class="heading d-flex align-items-center justify-content-between">
    <span>{{ title }}</span>
    <i class="fas fa-times" (click)="activeModal.dismiss()"></i>
  </div>

  <div ng-if="!properties.hidden" class="component col-xs-12 ng-scope i-message" ng-class="properties.cssClasses">
    <p style="margin-top: 10px">
      The email poller will run every day at the below selected time (in 24-hour clock format)
    </p>
  </div>

  <div class="set-url-modal-content">
    <form action="" [formGroup]="form" *ngIf="isForm && !loading && form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">Hours <label class="required"></label></label>

          <select id="hours" formControlName="hours" style="width: 60%; height: 30px">
            <option value="" disabled selected>Select an hour</option>
            <option *ngFor="let number of hours" [value]="number">{{ number }}</option>
          </select>

          <app-shared-form-error [control]="form.get('hours')" [name]="'Hours'"> </app-shared-form-error>
        </div>

        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">Minutes <label class="required"></label></label>

          <select id="hours" formControlName="minutes" style="width: 60%; height: 30px">
            <option value="" disabled selected>Select a minute</option>
            <option *ngFor="let number of minutes" [value]="number">{{ number }}</option>
          </select>
          <app-shared-form-error [control]="form.get('minutes')" [name]="'Minutes'"> </app-shared-form-error>
        </div>
      </div>
    </form>

    <div class="buttons-container d-flex align-items-center justify-content-end">
      <a *ngIf="closeButtons" style="padding: 7px" class="link" (click)="activeModal.dismiss()">{{
        closeButtons ?? 'Cancel'
      }}</a>
      <button
        *ngIf="successButtons"
        class="btn btn-primary"
        (click)="onSubmit()"
        [disabled]="isForm && (!isAnyFieldFilled || (form.get('hours').value == 0 && form.get('minutes').value == 0))"
      >
        {{ successButtons ?? 'Ok' }}
      </button>
    </div>
  </div>
</div>
