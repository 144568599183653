import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { HotToastService } from '@ngneat/hot-toast';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private toastService: HotToastService,
    private router: Router,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    var hasErrorHandler = false;
    var headers = request.headers;
    headers.keys().forEach((key) => {
      if (key == 'cust-err') hasErrorHandler = true;
    });
    if (hasErrorHandler) {
      return next.handle(request).pipe(
        map((res) => {
          return res;
        }),
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            //                               console.log('This is client side error');
            //                               errorMsg = `Error: ${error.error.message}`;
            //     throw new Error(error.error.status.toString(),{cause:error.error.message});
            return throwError({
              message: error.error.message,
            });
          } else {
            //                               console.log('This is server side error');
            //                               errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
            //      throw new Error(error.status.toString());
            return throwError({
              status: error.status,
              message: error.message,
              error: error.error,
            });
          }
        })
      );
    }
    return next.handle(request).pipe(catchError((error) => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    let errMsg: string;
    if (response instanceof HttpErrorResponse) {
      //notesAPIUrl Validation
      if (response.url.includes(environment.notesAPIUrl)) {
        // Wrong API i.e Server down => status === 0
        if (response.status === 0) {
          this.toastService.error('Issue in connecting to notesAPIUrl, Redirecting to Login Page.', { duration: 1500 });
          this.authenticationService
            .logout()
            .pipe(untilDestroyed(this))
            .subscribe(() =>
              this.router.navigate(['/login'], {
                queryParams: { redirect: this.router.routerState.snapshot.url },
                replaceUrl: true,
              })
            );
        } // DB down =>  status === 500
        else if (response.status === 500) {
          this.toastService.error('DB server is down please contact support.', { duration: 1500 });
        }
      } else if (response.status === 0) {
        errMsg = 'Sorry, our Server is down. Please try in a bit';
        this.toastService.error(errMsg);
      } else if (response.status === 401) {
        if (this.credentialsService.credentials != null) {
          if (!response?.url?.includes('/bonita/loginservice')) {
            this.toastService.error('Session Expired, Redirecting to Login Page.', {
              duration: 1500,
            });
            this.authenticationService
              .logout()
              .pipe(untilDestroyed(this))
              .subscribe(() =>
                this.router.navigate(['/login'], {
                  queryParams: { redirect: this.router.routerState.snapshot.url },
                  replaceUrl: true,
                })
              );
          }
        }
      } else {
        let err: string;
        if (this.isJson(response?.error?.error) && JSON.stringify(response?.error?.error)) {
          err = JSON.stringify(response.error.error).replace(/(^")|("$)/g, '');
        } else if (this.isJson(response?.error) && JSON.parse(response?.error)) {
          err = JSON.parse(response?.error)?.error?.message.replace(/(^")|("$)/g, '');
        } else {
          err = response.message.replace(/(^")|("$)/g, '');
        }
        errMsg = err;
        this.toastService.error(errMsg);
      }
    }
    throw response;
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
