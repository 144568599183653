import { CredentialsService } from './credentials.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl, NgForm, ValidationErrors } from '@angular/forms';
import { finalize, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthenticationService } from './authentication.service';
import { Logger } from '@app/@core';
import { HotToastService } from '@ngneat/hot-toast';
import { MockAPIService } from '@shared';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const log = new Logger('Login');

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  version: string | null = environment.version;
  error: string | undefined;
  customerror: string;
  loginForm!: FormGroup;
  createUserForm: FormGroup;
  countryList: any = [{ id: 1, name: 'Australia' }];
  isLoading = false;
  label = environment.label;
  production = environment.production;
  mode = '';
  failedComponents = [];
  logoURL: any;
  _LOGO_PATH = './assets/logo.png';
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private toastService: HotToastService,
    private apiService: MockAPIService,
    private modalService: NgbModal
  ) {
    this.apiService.getDbWSCheck().subscribe({
      next: (value: any) => {
        // mylogin
        this.route.queryParams.pipe(untilDestroyed(this)).subscribe((params) => {
          // get logo / company details
          var orgId = params['c'];
          var email = params['email'];
          var register = params['a'];
          if (register) {
            this.logoURL = this._LOGO_PATH;
            this.createLogin();
          } else if (orgId) {
            this.apiService.getOrgDetails(orgId).subscribe({
              next: (value: any) => {
                if (value && value?.logo) this.logoURL = value.logo;
                else this.logoURL = this._LOGO_PATH;
                this.createForm();
              },
              error: (error: any) => {
                console.log(error);
                this.logoURL = this._LOGO_PATH;
              },
              complete: () => {},
            });
          } else if (email) {
            this.apiService.getAccDetails(email).subscribe({
              next: (value: any) => {
                if (value && value?.logo) this.logoURL = value.logo;
                else this.logoURL = this._LOGO_PATH;
                this.createForm();
              },
              error: (error: any) => {
                console.log(error);
                this.logoURL = this._LOGO_PATH;
              },
              complete: () => {},
            });
          } else {
            this.logoURL = this._LOGO_PATH;
            this.createForm();
          }
        });
      },
      error: (error: any) => {
        this.failedComponents.push('DbWS');
      },
      complete: () => {},
    });
  }

  get userName() {
    return this.loginForm.get('userName');
  }

  get password() {
    return this.loginForm.get('password');
  }

  ngOnInit() {}

  loginValidation() {
    if (this.loginForm.valid) {
      this.apiService.loginCheck({ username: this.loginForm.value.userName }).subscribe((e) => {
        let res: any = e;
        if (res.status == 0) {
          if (this.mode === 'resetPwd') {
            this.handleResetPwd();
          } else {
            this.login();
          }
           
        } else if (res.status == -101) {
          this.customerror = 'Your profile has been deactivated, please contact admin';
          log.debug(`Login error: ${res.error}`);
          this.error = res.error;
          this.isLoading = false;
        } else if (res.status == -100) {
          this.customerror = 'Invalid username';
          log.debug(`Login error: ${res.error}`);
          this.error = res.error;
          this.isLoading = false;
        } else
          this.login();
      });
    }
  }

  login() {
    if (this.loginForm.valid) {
      this.isLoading = true;
      this.error = null;
      // const login$ = this.authenticationService.loginWithBonita(this.loginForm.value);
      const login$ = this.authenticationService.login(this.loginForm.value);
      login$
        .pipe(
          switchMap(() => {
            return this.authenticationService.getUserProfile();
          }),
          switchMap((userProfile) => {
            log.debug(`${this.loginForm?.value?.userName} successfully logged in`);
            this.credentialsService.setCredentials(userProfile, true);
            this.credentialsService.setSecCredentials();
            this.credentialsService.setSwitchas("Role");
            return this.authenticationService.checkLogin(userProfile?.id);
          }),
          finalize(() => {
            this.loginForm.markAsPristine();
            this.isLoading = false;
          }),
          untilDestroyed(this)
        )
        .subscribe(
          (checkLogin) => {
            const credentials = this.credentialsService.credentials;
            credentials.resetPassword = !checkLogin;
            this.credentialsService.setCredentials(credentials, true);
            this.apiService.getExtAppConnections().subscribe(
              (data) => {
                if (data.data.statusCode == 401 || data.data.status == 0) {
                  var _data = this.credentialsService.data;
                  _data['isDisconnected'] = true;
                  this.credentialsService.data = _data;
                  this.disconnect();
                } else {
                  var _data = this.credentialsService.data;
                  _data['isDisconnected'] = false;
                  this.credentialsService.data = _data;
                  this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
                }
              },
              (errors) => {
                this.disconnect();
              }
            );
          },
          (error) => {
            this.customerror = 'Incorrect password';
            log.debug(`Login error: ${error}`);
            this.error = error;
            this.isLoading = false;
          }
        );
    } else {
      this.validateAllFormFields(this.loginForm);
    }
  }
  disconnect() {
    if (
      this.credentialsService.roleName == 'Head of Organization' ||
      this.credentialsService.roleName == 'Administrator'
    ) {
      this.router.navigate(['/external-app']);
    } else {
      this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
    }
  }

  signUp() {
    this.apiService.createUser(this.createUserForm.value).subscribe(
      (data) => {
        console.log(data);
        if (data?.status == 0) {
          this.mode = 'postSignUp';
        } else {
          var _error = data?.error;
          var errIndex = _error.indexOf('Expression :');
          if (errIndex > -1) {
            _error = _error.substring(0, errIndex);
          }
          var parts = _error.split('|');
          this.error = parts[parts.length - 1].split(',');
        }
      },
      (error) => {
        //#TO_DO
        console.log('error in starting process');
      }
    );
  }
  getFormValidationErrors() {
    console.log('%c ==>> Validation Errors: ', 'color: red; font-weight: bold; font-size:25px;');

    let totalErrors = 0;

    Object.keys(this.createUserForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors = this.createUserForm.get(key).errors;
      if (controlErrors != null) {
        totalErrors++;
        Object.keys(controlErrors).forEach((keyError) => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });

    console.log('Number of errors: ', totalErrors);
  }
  createLogin() {
    this.error = undefined;
    let emailRegEx = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
    this.mode = 'createLogin'; // set to 'postSignUp' once the sign up is complete
    this.createUserForm = this.formBuilder.group({
      agencyname: ['', [/*Validators.required*/]],
      ccnumber: ['', [/*Validators.required*/]],
      website: ['', [/*Validators.required,*/ Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      telnumber: ['', [/*Validators.required,*/ Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      fax: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],

      buildnumber: ['', [/*Validators.required*/]],
      street: ['', [/*Validators.required*/]],
      cities: ['', [/*Validators.required*/]],
      country: ['', [/*Validators.required*/]],
      zipcode: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{4}$')]],

      title: ['', [/*Validators.required*/]],
      firstname: ['', [/*Validators.required,*/ Validators.pattern(/^(?:[a-zA-Z\s]+)?$/)]],
      lastname: ['', [/*Validators.required,*/ Validators.pattern(/^(?:[a-zA-Z\s]+)?$/)]],
      email: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(64), Validators.pattern(emailRegEx)],
      ],
      userName: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(64), Validators.pattern(emailRegEx)],
      ],

      telnumberPoc: ['', [/*Validators.required,*/ Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      faxPoc: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],

      buildnumberPoc: ['', [/*Validators.required*/]],
      streetPoc: ['', [/*Validators.required*/]],
      townPoc: ['', [/*Validators.required*/]],
      zipcodePoc: ['', [Validators.pattern('^((\\+91-?)|0)?[0-9]{4}$')]],
      countryPoc: [''],
      recaptchaReactive: [''/*, [Validators.required]*/],

      logoURL: ['', [/*Validators.required,*/ Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
      bannerURL: ['', Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')],
    });
    this.createUserForm.get('userName').valueChanges.subscribe((value) => {
      if (this.createUserForm.get('email').value != value) {
        this.createUserForm
          .get('userName')
          .setErrors({ custom: true, customMsg: 'Email address is not matching with confirm email ' });
        this.createUserForm.updateValueAndValidity();
      }
    });
    this.createUserForm.get('email').valueChanges.subscribe((value) => {
      if (this.createUserForm.get('userName').value != value) {
        this.createUserForm
          .get('userName')
          .setErrors({ custom: true, customMsg: 'Email address is not matching with confirm email ' });
        this.createUserForm.updateValueAndValidity();
      } else {
        this.createUserForm.get('userName').setErrors(null);
        this.createUserForm.updateValueAndValidity();
      }
    });
    this.createUserForm.valueChanges.subscribe((value) => {
      this.error = undefined;
    });
  }

  showReset() {
    this.mode = 'resetPwd';
    let username = this.loginForm?.get('userName').value;
    this.loginForm = this.formBuilder.group({});
    this.loginForm = this.formBuilder.group({
      userName: [username, [Validators.required]],
      remember: true,
    });
    this.loginForm.updateValueAndValidity();
  }

  resetMode() {
    this.error = undefined;
    this.mode = '';
    let username = this.loginForm?.get('userName').value;
    // this.loginForm = this.formBuilder.group({});
    this.loginForm = this.formBuilder.group({
      userName: [username, [Validators.required]],
      password: ['', [Validators.required]],
      remember: true,
      //recaptchaReactive: [null, [Validators.required]],
    });
    this.loginForm.updateValueAndValidity();
  }

  get isDefaultMode() {
    return this.mode === '';
  }

  get isResetPwdMode() {
    return this.mode === 'resetPwd';
  }
  get isCreateLogin() {
    return this.mode === 'createLogin';
  }
  get isPostSignUp() {
    return this.mode === 'postSignUp';
  }
  handleResetPwd() {
    // invoke reset pwd  bpm call
    this.apiService.resetPassword(this.loginForm?.get('userName')?.value).subscribe((result: any) => {
      if (result) {
        if (result.status === -1) {
          if (result.error.indexOf('org.bonitasoft.engine.identity.UserNotFoundException') > -1)
            this.toastService.error(
              `No user found with the username / email address "${
                this.loginForm?.get('userName')?.value
              }". Contact support.`
            );
          else if (result.error.indexOf('NoEmailFoundException') > -1)
            this.toastService.error(`No email address found. Contact support.`);
          else this.toastService.error('Reset password failed.Contact support.');
        } else {
          var msg = 'An email has been sent to your registered email address to reset the password.';
          this.toastService.show(msg, {
            autoClose: false,
            dismissible: true,
            icon: '✉️',
          });
          this.resetMode();
        }
      }
    });
  }

  private createForm() {
    this.loginForm = this.formBuilder.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      remember: true,
    });
  }

  validateAllFormFields(formGroup: FormGroup) {
    if (formGroup) {
      Object.keys(formGroup.controls).forEach((field) => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
          control.markAsTouched({
            onlySelf: true,
          });
        } else if (control instanceof FormGroup) {
          this.validateAllFormFields(control);
        }
      });
    }
  }

  remove(confirmation) {
    const ref = this.modalService.open(confirmation, {
      size: 'md',
      centered: true,
    });
    ref.result.then(
      (task) => {
        this.signUp();
      },
      (reason) => {}
    );
  }
  isChecked: boolean = false;
  onCheckboxChange() {
    this.isChecked = !this.isChecked;
    if (this.isChecked) {
      this.createUserForm.get('buildnumberPoc').setValue(this.createUserForm.get('buildnumber').value);
      this.createUserForm.get('streetPoc').setValue(this.createUserForm.get('street').value);
      this.createUserForm.get('townPoc').setValue(this.createUserForm.get('cities').value);
      this.createUserForm.get('countryPoc').setValue(this.createUserForm.get('country').value);
      this.createUserForm.get('zipcodePoc').setValue(this.createUserForm.get('zipcode').value);
    } else {
      this.createUserForm.get('buildnumberPoc').setValue(null);
      this.createUserForm.get('streetPoc').setValue(null);
      this.createUserForm.get('townPoc').setValue(null);
      this.createUserForm.get('countryPoc').setValue('');
      this.createUserForm.get('zipcodePoc').setValue(null);
    }
  }

  copyAddressenable() {
    return (
      this.createUserForm.get('buildnumber').valid &&
      this.createUserForm.get('street').valid &&
      this.createUserForm.get('cities').valid &&
      this.createUserForm.get('country').valid
    );
  }
}
