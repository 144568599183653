<div class="invalid" *ngIf="control && control!.invalid && (control!.dirty || control!.touched)">
  <small *ngIf="control?.errors?.required && !hide?.required"> {{ name }} is required. </small>
  <small *ngIf="control?.errors?.invalid"> {{ name }} is invalid. </small>

  <small *ngIf="control?.errors?.minlength">
    {{ name }} is required to be at least {{ control?.errors?.minlength.requiredLength }} character.
  </small>

  <small *ngIf="control?.errors?.maxlength">
    {{ name }} can't be longer than {{ control?.errors?.maxlength.requiredLength }} characters.
  </small>

  <small *ngIf="control?.errors?.pattern"> {{ name + ' is not valid' }} </small>

  <small *ngIf="control?.errors?.max"> {{ name }} can't be greater than {{ control?.errors?.max?.max }} </small>
  <small *ngIf="control?.errors?.min"> {{ name }} can't be less than {{ control?.errors?.min?.min }} </small>
  <small *ngIf="control?.errors?.hasNumber"> {{ name }} must contain at least 1 number! </small>
  <small *ngIf="control?.errors?.hasCapitalCase"> {{ name }} must contain at least 1 in Capital Case! </small>
  <small *ngIf="control?.errors?.hasNumber"> {{ name }} must contain at least 1 Letter in Small Case! </small>
  <small *ngIf="control?.errors?.hasSpecialCharacters"> {{ name }} must contain at least 1 Special Character! </small>
  <small *ngIf="control?.errors?.passwordNotMatch"> {{ name }} do not match. </small>
  <small *ngIf="control?.errors?.unique"> {{ name }} must be unique. </small>
  <small *ngIf="control?.errors?.dispatched"> {{ control?.errors?.dispatched }}</small>
  <small *ngIf="control?.errors?.minNumber"> {{ name }} must be greater than 0. </small>
  <small *ngIf="control?.errors?.dateRange"> {{ control?.errors?.dateRange }} </small>
  <small *ngIf="control?.errors?.amountRange"> {{ control?.errors?.amountRange }} </small>
  <small *ngIf="control?.errors?.valuePresent"> {{ control?.errors?.message }} </small>
  <small *ngIf="control?.errors?.incorrect"> {{ name + ' is not valid' }} </small>
  <small *ngIf="control?.errors?.custom"> {{ control?.errors?.customMsg }} </small>
</div>
