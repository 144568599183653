<div
  class="login-container"
  [ngClass]="{
    'large-mode': mode == 'createLogin',
    'post-signup-mode': mode == 'postSignUp'
  }"
>
  <div class="login-container__content">
    <!--    <div class="image-section">-->
    <!--      <img src="./assets/bg.svg" alt="Background Image" />-->
    <!--    </div>-->
    <div class="form-section d-flex align-items-center justify-content-center">
      <div class="w-100">
        <div class="form-section__logo d-flex justify-content-center">
          <div class="center">
            <div id="cloud"></div>
          </div>
          <div
            class="form-section__form-div align-items-center justify-content-center"
            *ngIf="failedComponents?.length > 0"
          >
            <div class=""><i class="fas fa-handshake-slash" style="font-size: xxx-large"></i></div>
          </div>
          <div
            class="form-section__form-div align-items-center justify-content-center"
            *ngIf="failedComponents?.length > 0"
          >
            <ul>
              <li>Database webservice is down</li>
            </ul>
          </div>
        </div>
        <div class="form-section__form-div bg-white rounded-3 shadow mx-auto" *ngIf="isPostSignUp">
          <div class="center" style="margin: 0 0 20px 0">
            <a href="/" *ngIf="logoURL"
              ><img [src]="logoURL" alt="Background Image" onerror="this.src='./assets/logo.png'"
            /></a>
            <a href="/" *ngIf="!logoURL"
              ><img [src]="_LOGO_PATH" alt="Background Image" onerror="this.src='./assets/logo.png'"
            /></a>
          </div>

          <div class="" style="font-weight: bold; font-size: larger">
            <h1 style="color: #000002">Voila!</h1>
            <h5 style="color: #060606">Your account is setup!</h5>
          </div>
          <div>
            Your organization account is setup and your account has been created successfully. Please follow the
            instructions sent in the welcome email sent to {{ createUserForm.get('userName').value }} to login.
          </div>
        </div>

        <div
          class="form-section__form-div bg-white rounded-3 shadow mx-auto"
          *ngIf="isCreateLogin"
          [formGroup]="createUserForm"
        >
          <div>
            <i style="margin-left: 99%" class="fas fa-times" (click)="resetMode()"></i>
          </div>
          <div class="center" style="margin: 0 0 20px 0">
            <a href="/"><img [src]="logoURL" alt="Background Image" onerror="this.src='./assets/logo.png'" /></a>
          </div>

          <div class="" style="font-weight: bold; font-size: larger">
            <h1 style="color: #060606">Create account</h1>
          </div>
          <div class="alert alert-dark" style="padding: 10px">
            Please fill-in the form below. Fields marked with <span style="color: red">*</span> are mandatory.
          </div>
          <div class="panel-body">
            <div class="form-group brdbot">
              <h5>Account Owner</h5>
            </div>
            <div class="panel-group">
              <div class="form-group">
              <label class="control-label col-sm-10 required">Email Address</label>
              <div class="input-div input-div-eq controls col-sm-10">
                <input
                  class="form-control k-textbox"
                  placeholder="Enter Email Address"
                  formControlName="email"
                  rrequired="required"
                  type="email"
                />
                <app-shared-form-error [control]="createUserForm.get('email')" [name]="'Email address'">
                </app-shared-form-error>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label col-sm-10 required">Confirm Email Address</label>
              <div class="input-div input-div-eq controls col-sm-10">
                <input
                  class="form-control k-textbox"
                  placeholder="Re-enter Email Address"
                  formControlName="userName"
                  rrequired="required"
                  type="text"
                  onpaste="return false"
                />
                <app-shared-form-error [control]="createUserForm.get('userName')" [name]="'Confirm email address'">
                </app-shared-form-error>
              </div>
            </div>
          </div>
            <div class="panel-group">
              <div class="form-group" style="display: block">
                <label class="control-label col-sm-10 rrequired">First Name </label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter First Name"
                    formControlName="firstname"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('firstname')" [name]="'First name'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Last Name</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Last Name"
                    formControlName="lastname"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('lastname')" [name]="'Last name'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Job Title</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="e.g. CEO, IT Auditor, Manager"
                    formControlName="title"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('title')" [name]="'Job title'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Mobile Number</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter without Country Code"
                    formControlName="telnumberPoc"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('telnumberPoc')" [name]="'Mobile number'">
                  </app-shared-form-error>
                </div>
              </div>
              <!--            <div class="form-group">-->
              <!--              <label class="control-label col-sm-10">Phone Number</label>-->
              <!--              <div class="controls col-sm-10">-->
              <!--                <input class="form-control k-textbox" placeholder="Enter Phone Number without Country Code"  formControlName="faxPoc"-->
              <!--                       type="text">-->
              <!--              </div>-->
              <!--            </div>-->
            </div>
<!--            <div class="form-group brdbot">
              <h5>User Address</h5>
            </div>
            <div class="control-label col-sm-10" style="margin-top: 10px; margin-bottom: 5px; font-weight: 500">
              <input type="checkbox" [disabled]="!copyAddressenable()" (change)="onCheckboxChange()" />
              <span style="margin-left: 5px">Copy Company Address</span>
            </div>
            <div class="panel-group">
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Building</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Building Name / Number"
                    formControlName="buildnumberPoc"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('buildnumberPoc')" [name]="'Building'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Street</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Street Name / Number"
                    formControlName="streetPoc"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('streetPoc')" [name]="'Street'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">City</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter City"
                    formControlName="townPoc"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('townPoc')" [name]="'City'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Country</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <select class="form-control" formControlName="countryPoc" rrequired="rrequired">
                    <option value="">Choose your country</option>
                    <option *ngFor="let country of countryList" [ngValue]="country.name">{{ country.name }}</option>
                  </select>
                  <app-shared-form-error [control]="createUserForm.get('countryPoc')" [name]="'Country'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10">Zip Code</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    type="text"
                    placeholder="Enter Zip Code"
                    formControlName="zipcodePoc"
                  />
                  <app-shared-form-error [control]="createUserForm.get('zipcodePoc')" [name]="'Zip code'">
                  </app-shared-form-error>
                </div>
              </div>
            </div> -->
            <div class="form-group brdbot">
              <h5>Company Details</h5>
            </div>
            <div class="panel-group">
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Company name</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Full Name of the Company"
                    formControlName="agencyname"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('agencyname')" [name]="'Company Name'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Registration number</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    pattern="[0-9.]+"
                    placeholder="Enter 11-digit ABN number"
                    formControlName="ccnumber"
                    minlength="11"
                    maxlength="11"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('ccnumber')" [name]="'Registration Number'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Website</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Website Address"
                    formControlName="website"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('website')" [name]="'Website'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Phone Number</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter without Country Code"
                    formControlName="telnumber"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('telnumber')" [name]="'Phone number'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10">Fax Number</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter without Country Code"
                    formControlName="fax"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('fax')" [name]="'Fax number'">
                  </app-shared-form-error>
                </div>
              </div>
            </div>
            <div class="form-group brdbot">
              <h5>Company Address</h5>
            </div>
            <div class="panel-group">
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Building</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Building Name / Number"
                    formControlName="buildnumber"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('buildnumber')" [name]="'Building'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Street</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Street Name / Number"
                    formControlName="street"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('street')" [name]="'Street'">
                  </app-shared-form-error>
                </div>
              </div>
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">City</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter City"
                    formControlName="cities"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('cities')" [name]="'City'">
                  </app-shared-form-error>
                </div>
              </div>
<!--              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Country</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <select class="form-control" formControlName="country" rrequired="required">
                    <option value="">Choose your country</option>
                    <option *ngFor="let country of countryList" [ngValue]="country.name">{{ country.name }}</option>
                  </select>
                  <app-shared-form-error [control]="createUserForm.get('country')" [name]="'Country'">
                  </app-shared-form-error>
                </div>
              </div> -->
              <div class="form-group">
                <label class="control-label col-sm-10">Zip Code</label>
                <div class="input-div input-div-eq controls col-sm-10">
                  <input
                    class="form-control k-textbox"
                    type="text"
                    placeholder="Enter Zip Code"
                    formControlName="zipcode"
                  />
                  <app-shared-form-error [control]="createUserForm.get('zipcode')" [name]="'Zip code'">
                  </app-shared-form-error>
                </div>
              </div>
            </div>


            <div class="form-group brdbot">
              <h5>Settings</h5>
            </div>
            <div class="panel-group">
              <div class="form-group">
                <label class="control-label col-sm-10 rrequired">Logo URL</label>
                <div class="input-div controls col-sm-10" style="width: 183%">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter URL to the logo image"
                    formControlName="logoURL"
                    rrequired="required"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('logoURL')" [name]="'Logo URL'">
                  </app-shared-form-error>
                </div>
              </div>
            </div>
            <div class="panel-group">
              <div class="form-group">
                <label class="control-label col-sm-10">Banner URL</label>
                <div class="input-div controls col-sm-10" style="width: 183%">
                  <input
                    class="form-control k-textbox"
                    placeholder="Enter Banner URL"
                    formControlName="bannerURL"
                    type="text"
                  />
                  <app-shared-form-error [control]="createUserForm.get('bannerURL')" [name]="'Banner URL'">
                  </app-shared-form-error>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="controls col-sm-12 d-flex justify-content-center">
                <re-captcha formControlName="recaptchaReactive"></re-captcha>
                <app-shared-form-error [control]="createUserForm.get('recaptchaReactive')" [name]="'Re-captcha'">
                </app-shared-form-error>
              </div>
            </div>
            <div class="form-group text-center">
              <button
                [disabled]="createUserForm.invalid"
                type="submit"
                class="btn btn-primary"
                (click)="remove(confirmation)"
              >
                Create account
              </button>
            </div>
            <div *ngIf="error && error?.length > 0" class="alert alert-danger">
              <ul>
                <li *ngFor="let err of error">
                  {{
                    err
                      ?.substring(err?.indexOf('constraints:'))
                      ?.substring(err?.indexOf('='))
                      .replace('constraints:', '')
                      .replace('=', '')
                      .trim()
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="form-section__form-div bg-white rounded-3 shadow mx-auto"
          *ngIf="loginForm && (isDefaultMode || isResetPwdMode)"
        >
          <div class="cloud" style="display: flex; justify-content: center; padding: 20px">
            <img [src]="logoURL" alt="Logo Image" onerror="this.src='./assets/logo.png'" style="max-height: 100px" />
          </div>
          <h4 class="text-center">Login</h4>
          <h3 style="background-color: #000002" class="text-light text-center" *ngIf="!production">
            {{ label | uppercase }}
          </h3>
          <div class="form-section__form-div--form">
            <form (ngSubmit)="loginValidation()" [formGroup]="loginForm" novalidate>
              <div class="alert alert-danger" [hidden]="!error || isLoading">{{ customerror }}</div>
              <div class="my-3">
                <label class="d-block my-3">
                  <div class="input-div">
                    <input
                      type="text"
                      class="form-control"
                      formControlName="userName"
                      autocomplete="userName"
                      [placeholder]="'Username'"
                    />
                    <i class="fas fa-user-alt"></i>
                  </div>
                  <small class="text-danger" *ngIf="userName.touched && userName.errors">Username is required.</small>
                </label>
                <label class="d-block mb-3" *ngIf="isDefaultMode">
                  <div class="input-div">
                    <i class="fas fa-lock"></i>
                    <input
                      type="password"
                      class="form-control"
                      formControlName="password"
                      autocomplete="current-password"
                      [placeholder]="'Password'"
                      required
                    />
                  </div>
                  <small class="text-danger" *ngIf="password.touched && password.errors">Password is required.</small>
                </label>
              </div>
              <div class="mt-3 tn-container d-flex justify-content-end">
                <!--                <div style="float: left; flex: 70%" *ngIf="isDefaultMode && false" >-->
                <!--                  <a class="link" (click)="showReset()" style="line-height: 35px">Reset Password</a>-->
                <!--                </div>-->
                <div style="float: left; flex: 70%" *ngIf="isDefaultMode">
                  <b
                    >New User?
                    <a class="link" (click)="createLogin()" style="line-height: 35px">Create a new account</a></b
                  >
                </div>
                <div style="float: left; flex: 45%" *ngIf="isResetPwdMode">
                  <a class="link" (click)="resetMode()" style="line-height: 35px">Login</a>
                </div>
                <button class="btn btn-primary" type="submit" [disabled]="isLoading" *ngIf="isDefaultMode">
                  <i class="fas fa-circle-notch fa-spin" [hidden]="!isLoading"></i>
                  <span>Login</span>
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  (click)="handleResetPwd()"
                  [disabled]="isLoading || !loginForm.get('userName')?.value"
                  *ngIf="isResetPwdMode"
                >
                  <i class="fas fa-circle-notch fa-spin" [hidden]="!isLoading"></i>
                  <span>Send Reset Password Link</span>
                </button>
              </div>
              <div class="mt-3 tn-container d-flex justify-content-end">
                <!--                <div style="float: left; flex: 70%" *ngIf="isDefaultMode">-->
                <!--                  <b>New User? <a class="link" (click)="createLogin()" style="line-height: 35px">Create a new account</a></b>-->
                <!--                </div>-->
              </div>
            </form>
          </div>
        </div>
        <div class="xmasTree"></div>
      </div>
    </div>
  </div>
  <!--  <div class="footer" [ngClass]="{-->
  <!--                      'large-mode-footer': mode == 'createLogin'-->
  <!--                    }">-->
  <!--    <p>Version: {{ version }} | Built &amp; Maintained by <a href="https://aarsfs.com" target="_blank">AARS FS</a></p>-->
  <!--  </div>-->
</div>

<!-- Confirmation Modal -->
<ng-template #confirmation let-modal>
  <div class="confirmation-modal">
    <div class="heading d-flex align-items-center justify-content-between">
      <span>Confirmation</span>
      <i class="fas fa-times" (click)="modal.dismiss()"></i>
    </div>
    <div class="confirmation-modal-content">
      <p class="message">You agree to the terms & conditions?</p>
    </div>
    <div class="buttons-container d-flex align-items-center justify-content-end gap-3">
      <a class="click" (click)="modal.dismiss()">Cancel</a>
      <button class="btn btn-primary" (click)="modal.close()">Yes, proceed</button>
    </div>
  </div>
</ng-template>
