import { ShellModule } from './shell/shell.module';
import { SharedModule } from './../@shared/shared.module';
import { PagesRoutingModule } from './pages.routing';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedModule, ShellModule, PagesRoutingModule],
})
export class PagesModule {}
