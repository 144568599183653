import { SharedChangePasswordComponent } from './components/change-password/change-password.component';

import { ByPassSecurityPipe } from './pipes/byPassSecurity.pipe';
import { TippyModule } from '@ngneat/helipopper';
import { SharedFormErrorComponent } from './components/form-error/form-error.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectPlaceHolderDirective } from './directives';
import { NgxBootstrapMultiselectModule } from 'ngx-bootstrap-multiselect';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPrintModule } from 'ngx-print';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { SharedModalComponent } from './modals/shared-modal/shared-modal.component';
import { SharedMultiDropdownComponent } from './components/multi-dropdown/multi-dropdown.component';
import { SchedulerModalComponent } from './modals/scheduler/scheduler-modal.component';
import { InboxSettingsModalComponent } from './modals/inbox-settings/inbox-settings-modal.component';
import { SharedConfirmationModalComponent } from './modals/confirmation-modal/confirmation-modal.component';
const DIRECTIVES = [NgSelectPlaceHolderDirective, SharedModalComponent, SchedulerModalComponent];
const PIPES = [ByPassSecurityPipe];

const COMPONENTS = [
  SharedFormErrorComponent,
  SharedChangePasswordComponent,
  SchedulerModalComponent,
  SharedMultiDropdownComponent,
  SharedConfirmationModalComponent,
  InboxSettingsModalComponent,
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxBootstrapMultiselectModule,
    NgxSkeletonLoaderModule,
    NgSelectModule,
    CKEditorModule,
    TippyModule,
    NgbModule,
    NgxPrintModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    NgCircleProgressModule.forRoot({}),
  ],
  declarations: [DIRECTIVES, PIPES, COMPONENTS],
  exports: [DIRECTIVES, PIPES, COMPONENTS],
})
export class SharedModule {}
