import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IMultiSelectOption, IMultiSelectSettings, IMultiSelectTexts } from 'ngx-bootstrap-multiselect';

@Component({
  selector: 'app-shared-multi-dropdown',
  templateUrl: './multi-dropdown.component.html',
  styleUrls: ['./multi-dropdown.component.scss'],
})
export class SharedMultiDropdownComponent implements OnInit {
  // Settings configuration
  mySettings: IMultiSelectSettings = {
    pullRight: false,
    enableSearch: false,
    checkedStyle: 'fontawesome',
    buttonClasses: 'btn btn-light w-100',
    containerClasses: 'w-100',
    selectionLimit: 0,
    closeOnSelect: false,
    autoUnselect: false,
    showCheckAll: true,
    showUncheckAll: true,
    fixedTitle: false,
    dynamicTitleMaxItems: 1,
    maxHeight: '400px',
    //isLazyLoad: true,
    loadViewDistance: 1,
    stopScrollPropagation: true,
    selectAddedValues: true,
    minSelectionLimit: 0,
  };
  myTexts: IMultiSelectTexts = {
    checkAll: 'Select all',
    uncheckAll: 'Unselect all',
    checked: 'selected',
    checkedPlural: 'selected',
    searchPlaceholder: 'Search',
    searchEmptyResult: 'Loading...',
    searchNoRenderText: 'Type in search box to see results...',
    defaultTitle: '',
    allSelected: 'All selected',
  };
  @Input() optionsModel: string[] = [];
  @Input() disable: boolean;
  @Input() set title(title: string) {
    this.myTexts.defaultTitle = title;
  }
  @Input() set dynamicTitleMaxItems(dynamicTitleMaxItems: number) {
    this.mySettings.dynamicTitleMaxItems = dynamicTitleMaxItems;
  }
  @Input() set minselection(minselectioncount: number) {
    this.mySettings.minSelectionLimit = minselectioncount;
  }
  @Input() set selectionLimit(selectionLimitcount: number) {
    this.mySettings.selectionLimit = selectionLimitcount;
  }
  @Input() set autoUnselect(autoUnselectFlag: boolean) {
    this.mySettings.autoUnselect = autoUnselectFlag;
  }
  @Input() set enableSearch(enableSearchFlag: boolean) {
    this.mySettings.enableSearch = enableSearchFlag;
  }
  @Input() set showCheckAll(showCheckAllFlag: boolean) {
    this.mySettings.showCheckAll = showCheckAllFlag;
  }
  @Input() set showUncheckAll(showUncheckAllFlag: boolean) {
    this.mySettings.showUncheckAll = showUncheckAllFlag;
  }
  @Input() set closeOnSelect(closeOnSelectFlag: boolean) {
    this.mySettings.closeOnSelect = closeOnSelectFlag;
  }
  @Input() optionList: IMultiSelectOption[];
  @Output() changeOut = new EventEmitter<string[]>();
  constructor() {}
  ngOnInit(): void {}
  onChange() {
    this.changeOut.emit(this.optionsModel);
  }
}
