import { Observable, of } from 'rxjs';

import { LoginContext } from './authentication.service';
import { Credentials } from './credentials.service';

export class MockAuthenticationService {
  login(context: LoginContext): Observable<Credentials> {
    return of();
  }
}
