import { CredentialsService } from '@app/auth';
import { Post } from './../interface/post.interface';
import { Roles } from '@app/@shared';
import { ChangePassword } from './../interface/change-password.interface';
import { User } from './../interface/user.interface';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { StrictHttpResponse } from '@app/@core/api/notes-module/strict-http-response';
import { RequestBuilder } from '@app/@core/api/notes-module/request-builder';
@Injectable()
export class MockAPIService {
  constructor(private http: HttpClient, private credentialsService: CredentialsService) {}

  getUsers(): Observable<User[]> {
    return this.http
      .get('/bonita/API/extension/getUsersByRole?p=0&c=10&roleName=ALL')
      .pipe(map((response: any) => response?.users as User[]));
  }
  getUserDetails(): Observable<any> {
    return this.http
      .get('/bonita/API/extension/getUsersByRole?p=0&c=10&roleName=ALL')
      .pipe(map((response: any) => response));
  }
  getRoles(): Observable<Roles[]> {
    return this.http.get<Roles[]>('/bonita/API/extension/getmisc?q=roles');
  }
  getRoleById(id: any): Observable<any> {
    return this.http.get<any>(`/bonita/API/identity/role/${id}`);
  }
  setPassword(changePassword: ChangePassword): Observable<any> {
    return this.post({
      key: 'ResetPassword',
      payload: {
        currentPwd: changePassword?.currentPassword,
        pwd: changePassword?.newPassword,
        uid: this.credentialsService.userId,
      },
    });
  }
  resetPassword(userName: string): Observable<any> {
    return this.post({
      key: 'ResetUserPassword',
      payload: {
        userName: userName,
      },
    });
  }

  getUserProfile(id: any): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('cust-err', 'true');
    return this.http.get<any>(`/bonita/API/identity/user/${id}`, { headers });
  }
  post(body: Post) {
    return this.http.post('/ws/post', body);
  }
  loginCheck(body: any) {
    return this.http.post('/ws/validateLogin', body);
  }
  postFile(form: FormData) {
    const myHeaders = new HttpHeaders();
    myHeaders.append('Content-Type', 'multipart/form-data');
    return this.http.post('/ws/postFile', form, {
      headers: myHeaders,
    });
  }

  getBonitaBPMCheck(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('cust-err', 'true');
    return this.http.get<any>(`${environment.serverUrl}`, { headers });
  }

  getDbWSCheck(): Observable<any> {
    return of(1);
  }

  getWSCheck(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('cust-err', 'true');
    return this.http.get<any>(`${environment.webUrl}/ws`, { headers });
  }

  getProcessVersion(filter: any): Observable<any> {
    const url = `${environment.notesAPIUrl}processes?filter=` + JSON.stringify(filter);
    return this.http.get(url);
  }

  getEnvIdDetails(data: any) {
    return this.http.post('/ws/getDetailsInEnv', data);
  }
  createUser(data?: any): Observable<any> {
    return this.post({
      key: 'UserRegistration',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  saveExtApp(data?: any): Observable<any> {
    return this.post({
      key: 'Add App Config',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  getExternalAppConfiguration(app: string): Observable<any> {
    return this.http.get<any>(
      `/bonita/API/extension/extapp?p=0&c=100&q=externalAppConfiguration&f=applicationType=${app}`
    );
    // return this.http.get<any[]>(`/bonita/API/bdm/businessData/com.company.model.ExternalAppConfiguration?p=0&c=100&q=findByAppIdAndOrgID&f=applicationType=${app}&f=organizationId=${organizationId}`);
  }
  xero(data?: any): Observable<any> {
    return this.post({
      key: 'Xero',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  xeroRefresh(data?: any): Observable<any> {
    return this.post({
      key: 'Xero Refresh',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  getTenants(): Observable<any> {
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=100&q=getTenants`);
  }

  getExtAppConnections(): Observable<any> {
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=100&q=checkConnection`);
  }
  Myob(data?: any): Observable<any> {
    return this.post({
      key: 'Myob',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  getPOByPONumber(poNumber): Observable<any> {
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=100&q=getPOsByABN&f=` + poNumber);
  }
  XeroDisConnect(data?: any): Observable<any> {
    return this.post({
      key: 'Xero DisConnect',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getOrgDetails(orgId): Observable<any> {
    return this.http.get<any>(`/ws/getOrgDetails?orgId=${orgId}`);
  }

  getAccDetails(email): Observable<any> {
    return this.http.get<any>(`/ws/getAccDetails?email=${email}`);
  }

  MyobDisConnect(data?: any): Observable<any> {
    return this.post({
      key: 'Myob DisConnect',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  UpdateTenantId(data?: any): Observable<any> {
    return this.post({
      key: 'Update TenantId',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  getLogoAndBanner(id): Observable<any[]> {
    return this.http.get<any[]>(
      `/bonita/API/bdm/businessData/com.company.model.Company?p=0&c=100&q=findById&f=id=${id}`
    );
  }
  saveStylingNew(data?: any): Observable<any> {
    return this.post({
      key: 'SaveStyling',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  readerGetExportSchedulerDetails(): Observable<any> {
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=100&q=exportScheduler`);
    //return this.http.get<any>(`/bonita/API/bdm/businessData/com.company.model.ExportScheduler?p=0&c=100&q=findByOrgId&f=orgId=${this.credentialsService.orgId}`);
  }
  readerGetExportDetails(sch?: any): Observable<any> {
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=100&q=settingsById&f=id=${sch}`);
    //return this.http.get<any>(`/bonita/API/bdm/businessData/com.company.model.Settings?p=0&c=100&q=findByOrgID&f=orgID=${this.credentialsService.orgId}`);
  }
  getemailPollerDetails(sch?: any): Observable<any> {
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=100&q=emailPollerDetails`);
  }
  getschedulerDetails(sch?: any): Observable<any> {
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=100&q=schedulerDetails`);
  }

  writerSettings(data?: any): Observable<any> {
    return this.post({
      key: 'writerSettings',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  startExportScheduler(data?: any): Observable<any> {
    return this.post({
      key: 'writerStartExportScheduler',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  stopExportScheduler(data?: any): Observable<any> {
    return this.post({
      key: 'writerStopExportScheduler',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  startScheduler(data?: any): Observable<any> {
    return this.post({
      key: 'writerStartScheduler',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  stopScheduler(data?: any): Observable<any> {
    return this.post({
      key: 'writerStopScheduler',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  settingCount(id: string): Observable<any> {
    return this.http.get<any>(
      `/bonita/API/bdm/businessData/com.company.model.Settings?p=0&c=100&q=countByIdAndOrgID&f=orgID=${this.credentialsService.orgId}&f=id=${id}`
    );
  }
  O365Post(data?: any): Observable<any> {
    return this.post({
      key: 'O365Post',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  writeScheduler(data?: any): Observable<any> {
    return this.post({
      key: 'writeScheduler',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  writeExportScheduler(data?: any): Observable<any> {
    return this.post({
      key: 'writerExportScheduler',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  readerGetTestConnectionResults(data?: any): Observable<any> {
    return this.post({
      key: 'readerGetTestConnectionResults',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  WriterEmailPoller(data?: any): Observable<any> {
    return this.post({
      key: 'WriterEmailPoller',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  sqlConnect(data?: any): Observable<any> {
    return this.post({
      key: 'sqlConnect',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  deleteSettings(data?: any): Observable<any> {
    return this.post({
      key: 'Delete Settings',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  deleteEmailPollerConnection(data?: any): Observable<any> {
    return this.post({
      key: 'DeleteEmailPollerConnection',
      payload: data,
    }).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  extappRestApi(q, app): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('cust-err', 'true');
    return this.http.get<any>(`/bonita/API/extension/extapp?p=0&c=1&q=${q}&app=${app.toLowerCase()}`, { headers });
  }
}
