<ng-container>
  <div *ngIf="resetPassword" class="heading align-items-center justify-content-start">
    <span>Change Password</span>
    <p>Please update your password as you are logging in for the first time</p>
  </div>
  <div class="main-change-password">
    <div class="policy-div">
      <h6>Password Policy</h6>
      <ul>
        <li
          [ngClass]="{
            checked:
              !currentPassword?.errors?.notEquivalent &&
              currentPassword.dirty &&
              currentPassword.value &&
              newPasswordControl.value,
            error:
              currentPassword?.errors?.notEquivalent &&
              currentPassword.dirty &&
              currentPassword.value &&
              newPasswordControl.value
          }"
        >
          Must be different from Current Password
          <i class="fas fa-check"></i>
          <i class="fas fa-times"></i>
        </li>
        <li
          [ngClass]="{
            checked: !newPasswordControl?.errors?.minlength && newPasswordControl.dirty && newPasswordControl.value,
            error: newPasswordControl?.errors?.minlength && newPasswordControl.dirty && newPasswordControl.value
          }"
        >
          Must be eight characters or longer.
          <i class="fas fa-check"></i>
          <i class="fas fa-times"></i>
        </li>
        <li
          [ngClass]="{
            checked: !newPasswordControl?.errors?.hasSmallCase && newPasswordControl.dirty && newPasswordControl.value,
            error: newPasswordControl?.errors?.hasSmallCase && newPasswordControl.dirty && newPasswordControl.value
          }"
        >
          Must contain at least 1 lower case alphabetical character.
          <i class="fas fa-check"></i>
          <i class="fas fa-times"></i>
        </li>
        <li
          [ngClass]="{
            checked:
              !newPasswordControl?.errors?.hasCapitalCase && newPasswordControl.dirty && newPasswordControl.value,
            error: newPasswordControl?.errors?.hasCapitalCase && newPasswordControl.dirty && newPasswordControl.value
          }"
        >
          Must contain at least 1 upper case alphabetical character.
          <i class="fas fa-check"></i>
          <i class="fas fa-times"></i>
        </li>
        <li
          [ngClass]="{
            checked: !newPasswordControl?.errors?.hasNumber && newPasswordControl.dirty && newPasswordControl.value,
            error: newPasswordControl?.errors?.hasNumber && newPasswordControl.dirty && newPasswordControl.value
          }"
        >
          Must contain at least 1 numeric character.
          <i class="fas fa-check"></i>
          <i class="fas fa-times"></i>
        </li>
        <li
          [ngClass]="{
            checked:
              !newPasswordControl?.errors?.hasSpecialChar && newPasswordControl.dirty && newPasswordControl.value,
            error: newPasswordControl?.errors?.hasSpecialChar && newPasswordControl.dirty && newPasswordControl.value
          }"
        >
          Must contain at least one special character
          <i class="fas fa-check"></i>
          <i class="fas fa-times"></i>
        </li>
        <li
          [ngClass]="{
            checked: !confirmPassword?.errors && confirmPassword.dirty && confirmPassword.value,
            error: confirmPassword?.errors && confirmPassword.dirty && confirmPassword.value
          }"
        >
          Confirm password must be same as new password
          <i class="fas fa-check"></i>
          <i class="fas fa-times"></i>
        </li>
      </ul>
    </div>
    <div class="change-pwd-form">
      <form (ngSubmit)="save()" [formGroup]="form">
        <div class="d-flex align-items-center gap-5">
          <div class="input-fields">
            <div class="input-section">
              <label for="current" class="d-block mobile-label">Current Password <span>*</span></label>
              <div class="input-div">
                <input
                  id="currentPassword"
                  type="password"
                  class="d-block"
                  formControlName="currentPassword"
                  autocomplete="currentPassword"
                  [placeholder]="'Current Password'"
                  oninput="validity.valid"
                  required
                />
                <i class="fas fa-lock"></i>
              </div>
            </div>
            <!--            <app-shared-form-error-->
            <!--              [control]="form.get('currentPassword')"-->
            <!--              [name]="'Current Password'"-->
            <!--              [hide]="{ required: true }"-->
            <!--            ></app-shared-form-error>-->
            <div class="input-section">
              <label for="new" class="d-block mobile-label">New Password <span>*</span></label>
              <div class="input-div">
                <input
                  id="newPassword"
                  type="password"
                  class="d-block"
                  formControlName="newPassword"
                  autocomplete="newPassword"
                  [placeholder]="'New Password'"
                  oninput="validity.valid"
                  minlength="8"
                  required
                />
                <i class="fas fa-lock"></i>
              </div>
            </div>
            <div class="input-section">
              <label for="confirm" class="d-block mobile-label">Confirm Password <span>*</span></label>
              <div class="input-div">
                <input
                  id="confirmPassword"
                  type="password"
                  class="d-block"
                  formControlName="confirmPassword"
                  autocomplete="confirmPassword"
                  [placeholder]="'Confirm Password'"
                  oninput="validity.valid"
                  minlength="8"
                  required
                  onpaste="return false"
                />
                <i class="fas fa-lock"></i>
              </div>
            </div>
            <!-- <app-shared-form-error
              [control]="form.get('confirmPassword')"
              [name]="'Confirm Password'"
              [hide]="{ required: true }"
            ></app-shared-form-error> -->
          </div>
        </div>
        <div class="mt-3 tn-container d-flex justify-content-end">
          <button class="btn btn-primary" type="submit" (click)="save()" [disabled]="form.invalid || isLoading">
            <i class="fas fa-circle-notch fa-spin" [hidden]="!isLoading"></i>
            <span>{{ btnText }}</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container>
