import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-shared-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class SharedFormErrorComponent implements OnInit {
  @Input()
  set control(form: AbstractControl) {
    this._formControl = form;
  }
  get control(): AbstractControl {
    return this._formControl;
  }
  @Input() hide: any;
  @Input() name: string;
  @Input() patternError = 'can only contain letters and digits';
  private _formControl: AbstractControl;
  constructor() {}
  ngOnInit(): void {}
}
