/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Login } from '../models/login';
import { LoginWithRelations } from '../models/login-with-relations';
import { NewLogin } from '../models/new-login';

@Injectable({
  providedIn: 'root',
})
export class LoginControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /**
   * Path part for operation loginControllerFind
   */
  static readonly LoginControllerFindPath = '/logins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: { filter?: any }): Observable<StrictHttpResponse<Array<LoginWithRelations>>> {
    const rb = new RequestBuilder(this.rootUrl, LoginControllerService.LoginControllerFindPath, 'get');
    if (params) {
      rb.query('filter', params.filter, {});
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Array<LoginWithRelations>>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: { filter?: any }): Observable<Array<LoginWithRelations>> {
    return this.find$Response(params).pipe(
      map((r: StrictHttpResponse<Array<LoginWithRelations>>) => r.body as Array<LoginWithRelations>)
    );
  }

  /**
   * Path part for operation loginControllerCreate
   */
  static readonly LoginControllerCreatePath = '/logins';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: { body?: NewLogin }): Observable<StrictHttpResponse<Login>> {
    const rb = new RequestBuilder(this.rootUrl, LoginControllerService.LoginControllerCreatePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http
      .request(
        rb.build({
          responseType: 'json',
          accept: 'application/json',
        })
      )
      .pipe(
        filter((r: any) => r instanceof HttpResponse),
        map((r: HttpResponse<any>) => {
          return r as StrictHttpResponse<Login>;
        })
      );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: { body?: NewLogin }): Observable<Login> {
    return this.create$Response(params).pipe(map((r: StrictHttpResponse<Login>) => r.body as Login));
  }
}
