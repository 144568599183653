import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Injectable()
export class Util {
  private CR_PROCESS_KEY = 'DOC_MGMT';
  private CR_PROCESS_CODE = 'CR';

  public formatId(processCode: string, id: any) {
    return processCode + '-' + id?.toString().padStart(4, '0');
  }

  public getFormKeyFromPath(link: string) {
    if (!link) return '';
    return (
      this.CR_PROCESS_KEY +
      '_' +
      link
        .replace(/^.*[\\\/]/, '')
        .replace(/-/, '_')
        .toUpperCase()
    );
  }
  public getFormKeyFromRoute(route: ActivatedRoute) {
    return this.getFormKeyFromPath(route.snapshot.url[0].path);
  }

  public sort(result: any[], column: string, direction: string): any[] {
    return [...result].sort((a, b) => {
      if (a[column] === null || a[column] === undefined || a[column] === '') {
        return 1;
      }
      if (b[column] === null || b[column] === undefined || b[column] === '') {
        return -1;
      }
      const res = this.compare(
        _.get(a, column)?.toString()?.toLowerCase(),
        _.get(b, column)?.toString()?.toLowerCase()
      );
      return direction === 'asc' ? res : -res;
    });
  }
  public compare = (v1: string | number, v2: string | number) => {
    const a = !isNaN(+v1) ? +v1 : v1;
    const b = !isNaN(+v2) ? +v2 : v2;
    const sort = a < b ? -1 : a > b ? 1 : 0;
    return sort;
  };
}
