import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormService, MockAPIService } from '@app/@shared/services';
import { CredentialsService } from '@app/auth';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Util } from '@app/@shared/services/util.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class SharedConfirmationModalComponent extends FormService implements OnInit {
  title: string;
  message: string;
  successButtons: string;
  closeButtons: string;

  loading: boolean;

  constructor(public activeModal: NgbActiveModal, private modalService: NgbModal, public changeRef: ChangeDetectorRef) {
    super();
  }

  ngAfterContentInit() {
    this.loading = true;
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  onSubmit() {
    this.activeModal.close();
  }

  ngOnInit() {}
}
