<header>
  <nav class="navbar navbar-expand-lg navbar-light pt-2" [ngClass]="secUser ? 'switch-black' : 'switch-white'">
    <div class="container-fluid">
      <a class="navbar-brand" (click)="show = !show"
        ><i [ngClass]="secUser ? 'switch-black' : ''" class="fas fa-bars"></i
      ></a>
      <button
        class="navbar-toggler"
        type="button"
        aria-controls="navbar-menu"
        aria-label="Toggle navigation"
        (click)="toggleMenu()"
        [attr.aria-expanded]="!menuHidden"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
        <div class="navbar-nav me-auto mb-2 mb-lg-0">
          <a class="nav-item the-one">
            <img *ngIf="logoURL" [src]="logoURL" alt="Logo" height="40px" />
            <img *ngIf="!logoURL" src="./assets/logo.png" alt="Logo" height="40px" />
            <span
              ><strong [ngClass]="secUser ? 'switch-black' : ''">{{customInfo?.[0].value}}</strong></span
            >
          </a>
          <a class="nav-item nav-link nav-overview" (click)="menuHidden = true" routerLink="/dashboard">
            <i [ngClass]="secUser ? 'switch-black' : ''"> Overview </i></a
          >
          <ng-container *ngFor="let route of currentRoute">
            <div class="line-tilt" *ngIf="route?.route"></div>
            <span
              class="nav-item nav-link nav-overview no-border"
              (click)="menuHidden = true"
              [routerLink]="route?.link"
              *ngIf="route?.route"
              ><i [ngClass]="secUser ? 'switch-black' : ''">{{ formatName(route?.route) }}</i>
            </span>
          </ng-container>
        </div>
        <div class="navbar-nav ml-auto d-flex align-items-center">
          <div class="user-cog" *ngIf="isAdmin">
            <i
              ngbPopover="Admin access enabled"
              placement="auto"
              triggers="mouseenter:mouseleave:click"
              popoverClass="clz-tooltip-popover"
              class="nav-bar-icon fas fa-user-cog"
              style="cursor: auto"
            ></i>
          </div>
          <div class="nav-item user-name" ngbDropdown>
            <p class="mb-0 d-flex align-items-baseline">
              <span class="d-block h6 name"
                ><i [ngClass]="secUser ? 'switch-black' : ''">{{ name }}</i></span
              ><span class="role d-block font-weight-light"
                ><i [ngClass]="secUser ? 'switch-black' : ''">{{ jobTitle }}</i></span
              >
            </p>
          </div>

          <!-- Normal User   -->
          <div class="nav-item" ngbDropdown placement="bottom-left">
            <a id="user-dropdown" class="nav-link" ngbDropdownToggle style="display: flex; align-items: center">
              <span class="navbar-user-initials" *ngIf="!user?.iconId"
                ><span
                  >{{ user?.firstName?.slice(0, 1) | titlecase }}{{ user?.lastName?.slice(0, 1) | titlecase }}</span
                ></span
              >
              <!--              <app-shared-user-avatar [class]="'header-img'" [iconId]="user?.iconId"></app-shared-user-avatar>-->
            </a>
            <div
              ngbDropdownMenu
              aria-labelledby="user-dropdown"
              class="dropdown-menu dropdown-menu-right nav-logout-menu"
              [style.margin-left]="'-55px !important'"
            >
              <button
                *ngIf="isAdmin && views?.adminSettings"
                class="dropdown-item"
                (click)="menuHidden = true"
                routerLink="/admin-settings"
              >
                <i class="fas fa-user-cog" style="padding: 0px; margin-right: 4px"></i>
                Admin Settings
              </button>
              <div *ngIf="isAdmin && views?.adminSettings" class="dropdown-divider"></div>
              <button
                class="dropdown-item"
                routerLink="/profile"
                (click)="this.addToRoute('Profile', '/profile'); menuHidden = true"
              >
                <img src="./assets/user.svg" />
                Profile
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" (click)="logout(); menuHidden = true">
                <img src="./assets/logout.svg" />
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!-- Navbar -->
</header>
<router-outlet></router-outlet>
