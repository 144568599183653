<div class="set-url-inbox-modal">
  <div class="heading d-flex align-items-center justify-content-between">
    <span>{{ title }}</span>

    <i class="fas fa-times" (click)="activeModal.dismiss()"></i>
  </div>

  <div *ngIf="!errorMessage" class="component col-xs-12 ng-scope i-message" ng-class="properties.cssClasses">
    <p style="margin-top: 10px">
      Click on <b>Test Connection</b> button verify your connection and save your email configuration changes
    </p>
  </div>

  <div
    *ngIf="errorMessage"
    class="component col-xs-12 ng-scope bg-danger p-2 text-white"
    ng-class="properties.cssClasses"
  >
    <p style="margin-top: 10px">{{ errorMessage }}</p>
  </div>

  <div class="set-url-inbox-modal-content">
    <form action="" [formGroup]="form" *ngIf="isForm && !loading && form" (ngSubmit)="onSubmit()">
      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">Name<span class="required">*</span></label>
          <input formControlName="name" placeholder="Enter Name Details" type="text" required="required" />
        </div>

        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">ServerType<span class="required">*</span></label>
          <select id="hours" formControlName="serverType" style="width: 60%; height: 43px">
            <option value="" disabled selected>Select Server Type</option>
            <option *ngFor="let number of serverType" [value]="number">{{ number }}</option>
          </select>
        </div>
      </div>

      <div class="row" style="margin-top: 20px">
        <div class="col-md-6" style="text-align: center">
          <h4>Server Information</h4>
        </div>

        <div class="col-md-6" style="text-align: center">
          <h4>Login Information</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">Server Name<span class="required">*</span></label>
          <input formControlName="serverName" placeholder="Enter Server Name" type="text" required="required" />
        </div>

        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">UserName<span class="required">*</span></label>
          <input formControlName="userName" placeholder="Enter UserName" type="text" required="required" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">Port<span class="required">*</span></label>
          <input formControlName="port" placeholder="Enter Port" type="text" required="required" />
        </div>

        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">Password<span class="required">*</span></label>
          <input formControlName="password" placeholder="Enter password" type="password" required="required" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-6">
          <label class="control-label pe-2 popup-label">Label / Folder<span class="required">*</span></label>
          <input formControlName="folder" required="required" placeholder="INBOX / sub-folder / label" type="text" />
        </div>

        <div class="col-md-6" style="padding: 10px">
          <div style="margin-left: 30%">
            <input type="checkbox" style="margin-left: 5px" formControlName="ssltls" />
            <span>SSL/TLS</span>
          </div>
        </div>
      </div>
    </form>

    <div class="buttons-container d-flex align-items-center justify-content-end">
      <a *ngIf="closeButtons" style="padding: 7px" class="link" (click)="activeModal.dismiss()">{{
        closeButtons ?? 'Cancel'
      }}</a>
      <a *ngIf="this.data?.persistenceId && !disableForm" class="link p-3" (click)="deleteSetting()"
        >Delete Configuration</a
      >
      <button
        *ngIf="successButtons"
        class="btn btn-primary"
        (click)="testConnection(confirmation)"
        [disabled]="isForm && !isAnyFieldFilled"
      >
        {{ successButtons ?? 'Ok' }}
      </button>
    </div>
  </div>
</div>

<!-- Confirmation Modal -->
<ng-template #confirmation let-modal>
  <div class="confirmation-modal">
    <div class="heading d-flex align-items-center justify-content-between">
      <span>Success</span>
      <i class="fas fa-times" (click)="modal.close()"></i>
    </div>
    <div class="confirmation-modal-content">
      Your connection is successful. Click on <b>Save</b> button to save your email server configuration. Click on
      <b>Cancel</b> to ignore the changes.
    </div>
    <div class="buttons-container d-flex align-items-center justify-content-end gap-3">
      <a class="click" class="btn btn-primary" (click)="onSubmit(); modal.close()">Save</a>
    </div>
  </div>
</ng-template>
