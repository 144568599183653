import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ScopeFilter } from '@app/@core';
import { HelperService, MockAPIService } from '@app/@shared';
import { environment } from '@env/environment';
import { CredentialsService } from '@app/auth';
@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() showSide: boolean = false;
  @Output() showSearch = new EventEmitter<boolean>();
  search: boolean = false;
  activeIds: string[];
  version = environment.version;
  secUser: Boolean;
  view: string;
  routePath: string;
  headOfOrg: boolean;
  appDisconncted: boolean;
  allowedWithRestrictedAccess = {
    INBOX: false,
    ALLDOCS: true,
    REQUESTS: false,
    FIELDS: true,
    DATASETS: true,
    SCHEDULER: false,
    SCHEDULERS: false,
    USERS: true,
  };
  constructor(
    private router: Router,
    private helperService: HelperService,
    private credentialsService: CredentialsService,
    private route: ActivatedRoute,
    private apiService: MockAPIService
  ) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        this.apiService.getExtAppConnections().subscribe(
          (data) => {
            data.data.statusCode == 401 || data.data.status == 0
              ? (this.appDisconncted = true)
              : (this.appDisconncted = false);
            this.routing();
          },
          (error) => {
            this.appDisconncted = true;
            this.routing();
          }
        );
      }
    });
  }
  hideWorkHooAdmin: boolean = false;
  routing() {
    if (
      (this.credentialsService.roleName == 'Head of Organization' ||
        this.credentialsService.roleName == 'Administrator') &&
      this.appDisconncted
    ) {
      this.hideWorkHooAdmin = true;
    }
    this.route.queryParams.subscribe((params) => {
      this.view = params.view;
      if (this.router.url.includes('external-app')) {
        this.view = 'EXT';
      } else if (this.router.url.includes('settings')) {
        this.view = 'SETTINGS';
      } else if (this.router.url.includes('schedulers')) {
        this.view = 'SCHEDULERS';
      } else if (!this.routePath && !params.view) {
        this.view = 'INBOX';
      }
    });
  }
  ngOnInit(): void {
    this.headOfOrg = this.credentialsService.roleName == 'Head of Organization';

    this.activeIds = [];
    this.helperService.routerInfo.pipe(untilDestroyed(this)).subscribe((route) => {
      if (route && route[0]?.route) this.routePath = route[0].route;
      else this.routePath = null;
    });
  }
  ngOnChanges() {}
  addToRoute(name: string, link: string) {
    this.helperService.addRouterInfo([{ route: name, link: link }]);
  }
  get isProduction() {
    return environment.production;
  }

  get isAdmin(): boolean {
    if (this.credentialsService.isAdmin) {
      return this.credentialsService.isAdmin;
    } else {
      return this.credentialsService.roleName == 'Administrator';
    }
  }
  pushintolist(listname: string) {
    var flag: boolean = false;
    this.activeIds.forEach((element, index) => {
      if (element == listname) {
        this.activeIds.splice(index, 1);
        flag = true;
      }
    });
    if (!flag) this.activeIds.push(listname);
  }
}
