import { UntilDestroy } from '@ngneat/until-destroy';
import { Directive, ElementRef } from '@angular/core';

@UntilDestroy()
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngSelectPlaceHolder]',
})
export class NgSelectPlaceHolderDirective {
  constructor(el: ElementRef) {
    const element = el.nativeElement;
    const timeout = setTimeout(() => {
      (element.querySelector(`#${element.id} .ng-input input`) as any).placeholder =
        'Select or add possible statuses applicable';
      clearTimeout(timeout);
    }, 100);
  }
}
