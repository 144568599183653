import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormService, MockAPIService } from '@app/@shared/services';
import { CredentialsService } from '@app/auth';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Util } from '@app/@shared/services/util.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SharedConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-shared-modal',
  templateUrl: './shared-modal.component.html',
  styleUrls: ['./shared-modal.component.scss'],
})
export class SharedModalComponent extends FormService implements OnInit {
  title: string;
  successButtons: string;
  closeButtons: string;

  loading: boolean;
  disableForm: boolean;

  isForm: boolean = false;
  formType?: string;
  data?: any = {
    abbyServer: '',
    abbyPort: '',
    abbyUsername: '',
    abbyPassword: '',
    orgID: '',
    ftpPortocol: '',
    ftpServer: '',
    ftpPort: '',
    ftpUsername: '',
    ftpPassword: '',
    dbURL: '',
    dbUsername: '',
    dbPassword: '',
    id: '',
    val: '',
    val2: '',
  };

  constructor(
    public activeModal: NgbActiveModal,
    private credentialsService: CredentialsService,
    private apiService: MockAPIService,
    private formBuilder: FormBuilder,
    private router: Router,
    private util: Util,
    private modalService: NgbModal,
    public changeRef: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterContentInit() {
    this.loading = true;
    this.apiService.readerGetExportDetails('SCHEDULERS').subscribe((e) => {
      if (e?.data?.status == 0) {
        this.data = e?.data?.message;
        this.initForm(e?.data?.message);
      } else {
        this.initForm();
      }
    });
  }

  onSubmit() {
    this.data.ftpPortocol = this.mapvalue(this.protocol, this.form.get('protocol').value);
    this.data.ftpServer = this.form.value.server;
    this.data.ftpUsername = this.form.value.username;
    this.data.ftpPort = this.form.value.port;
    this.data.ftpPassword = this.form.value.password;
    this.data.id = 'SCHEDULERS';

    let payload = { writeSettingsInput: this.data, userId: this.credentialsService.userId.toString() };
    this.activeModal.close(payload);
  }

  mapvalue(list, id) {
    var ob = list.find((element) => element.id === id[0]);
    if (ob?.searchKey) return ob.searchKey;
    if (ob?.name) return ob.name;
    return '';
  }
  mapName(list, name) {
    var ob = list.find((element) => element.name === name);
    return [ob.id];
  }
  protocol = [{ id: 1, name: 'SFTP' }];

  isAnyFieldFilled: boolean = false;
  initForm(data?) {
    this.form = this.formBuilder.group({
      protocol: [data?.ftpPortocol ? this.mapName(this.protocol, data?.ftpPortocol) : null, [Validators.required]],
      server: [data?.ftpServer, [Validators.required]],
      username: [data?.ftpUsername, [Validators.required]],
      port: [data?.ftpPort, [Validators.required]],
      password: [data?.ftpPassword, [Validators.required]],
    });
    this.form.valueChanges.subscribe(() => {
      this.isAnyFieldFilled = this.checkIfAnyFieldFilled();
    });
    this.loading = false;
    if (this.disableForm) {
      this.form.disable();
      this.changeRef.detectChanges();
    }
  }
  checkIfAnyFieldFilled(): boolean {
    const formValues = this.form.value;
    if (this.form.valid)
      for (const key in formValues) {
        if (formValues.hasOwnProperty(key) && formValues[key]) {
          return true;
        }
      }
    return false;
  }
  ngOnInit() {}
  deleteSetting() {
    const ref = this.modalService.open(SharedConfirmationModalComponent, {
      centered: true,
      size: 'md',
      keyboard: false,
      backdrop: 'static',
    });
    ref.componentInstance.title = 'Confirmation';
    ref.componentInstance.message = 'Are you sure ?';
    ref.componentInstance.successButtons = 'Yes';
    ref.componentInstance.closeButtons = 'Cancel';
    ref.result?.then(
      (results) => {
        this.apiService.deleteSettings({ id: String(this.data.persistenceId) }).subscribe((e) => {
          this.activeModal.dismiss(this.data);
        });
      },
      (error) => {}
    );
  }
}
