import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormService, MockAPIService } from '@app/@shared/services';
import { CredentialsService } from '@app/auth';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Util } from '@app/@shared/services/util.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { SharedConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-inbox-settings-modal',
  templateUrl: './inbox-settings-modal.component.html',
  styleUrls: ['./inbox-settings-modal.component.scss'],
})
export class InboxSettingsModalComponent extends FormService implements OnInit {
  title: string;
  successButtons: string;
  closeButtons: string;

  loading: boolean;
  disableForm: boolean;

  isForm: boolean = false;
  formType?: string;
  errorMessage: string;
  data?: any = {
    password: '',
    userName: '',
    appId: '13',
    serverType: '',
    port: '',
    folder: '',
    ssltls: false,
    orgId: this.credentialsService.orgId,
    serverName: '',
    name: '',
  };

  constructor(
    public activeModal: NgbActiveModal,
    private credentialsService: CredentialsService,
    private apiService: MockAPIService,
    private formBuilder: FormBuilder,
    private router: Router,
    private util: Util,
    private modalService: NgbModal,
    public changeRef: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterContentInit() {
    this.loading = true;
    this.initForm(this.data);
  }

  onSubmit() {
    this.data.requestInput.appId = Number(this.data.requestInput.appId);
    this.activeModal.close(this.data);
  }

  serverType: any[] = ['imaps'];

  isAnyFieldFilled: boolean = false;
  initForm(data?) {
    this.form = this.formBuilder.group({
      password: [data?.password, [Validators.required]],
      userName: [data?.userName, [Validators.required]],
      serverType: [data?.serverType ? data?.serverType : '', [Validators.required]],
      port: [data?.port, [Validators.required]],
      folder: [data?.folder],
      ssltls: [data?.ssltls ? data?.ssltls : false, [Validators.required]],
      serverName: [data?.serverName, [Validators.required]],
      name: [data?.name, [Validators.required]],
    });
    this.form.valueChanges.subscribe(() => {
      this.isAnyFieldFilled = this.checkIfAnyFieldFilled();
    });
    this.loading = false;
    if (this.disableForm) {
      this.form.disable();
      this.changeRef.detectChanges();
    }
  }
  checkIfAnyFieldFilled(): boolean {
    const formValues = this.form.value;
    if (this.form.valid)
      for (const key in formValues) {
        if (formValues.hasOwnProperty(key) && formValues[key]) {
          return true;
        }
      }
    return false;
  }
  ngOnInit() {}

  testConnection(confirmation) {
    this.data = {
      requestInput: {
        password: this.form.value.password,
        userName: this.form.value.userName,
        appId: '13',
        serverType: this.form.value.serverType,
        port: this.form.value.port,
        folder: this.form.value.folder,
        ssltls: this.form.value.ssltls,
        orgId: this.credentialsService.orgId,
        serverName: this.form.value.serverName,
        name: this.form.value.name,
      },
    };
    this.errorMessage = null;
    this.apiService.readerGetTestConnectionResults(this.data).subscribe((e) => {
      switch (e?.status) {
        case 0:
          this.previewUiForm(confirmation);
          break;
        case -1:
          if (e?.error.indexOf('javax.mail.AuthenticationFailedException') > -1) {
            this.errorMessage = 'Authentication failed. Please check username / password.';
          } else {
            this.errorMessage = 'Technical error occurred. Contact support.';
          }
          break;
      }
    });
  }

  previewUiForm(confirmation) {
    const ref = this.modalService.open(confirmation, {
      size: 'md',
      centered: true,
    });
  }
  deleteSetting() {
    const ref = this.modalService.open(SharedConfirmationModalComponent, {
      centered: true,
      size: 'md',
      keyboard: false,
      backdrop: 'static',
    });
    ref.componentInstance.title = 'Confirmation';
    ref.componentInstance.message = 'Are you sure ?';
    ref.componentInstance.successButtons = 'Yes';
    ref.componentInstance.closeButtons = 'Cancel';
    ref.result?.then(
      (results) => {
        this.apiService.deleteEmailPollerConnection({ id: this.credentialsService.orgId }).subscribe((e) => {
          this.activeModal.dismiss(this.data);
        });
      },
      (error) => {}
    );
  }
}
