import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormService, MockAPIService } from '@app/@shared/services';
import { CredentialsService } from '@app/auth';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Util } from '@app/@shared/services/util.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Component({
  selector: 'app-scheduler-modal',
  templateUrl: './scheduler-modal.component.html',
  styleUrls: ['./scheduler-modal.component.scss'],
})
export class SchedulerModalComponent extends FormService implements OnInit {
  title: string;
  successButtons: string;
  closeButtons: string;

  loading: boolean;

  isForm: boolean = false;
  formType?: string;
  data?: any;

  constructor(
    public activeModal: NgbActiveModal,
    private credentialsService: CredentialsService,
    private apiService: MockAPIService,
    private formBuilder: FormBuilder,
    private router: Router,
    private util: Util,
    private modalService: NgbModal,
    public changeRef: ChangeDetectorRef
  ) {
    super();
  }

  ngAfterContentInit() {
    this.loading = true;
    this.initForm(this.data);
  }

  onSubmit() {
    let payload = {
      writeSchedulerInput: {
        orgId: this.credentialsService.orgId,
        hours: Number(this.form.value.hours),
        minintes: Number(this.form.value.minutes),
      },
    };
    this.activeModal.close(payload);
  }

  hours: any[] = Array.from({ length: 60 }, (_, i) => i);
  minutes: any[] = Array.from({ length: 60 }, (_, i) => i);

  isAnyFieldFilled: boolean = false;
  initForm(data?) {
    this.form = this.formBuilder.group({
      hours: [data?.hours > -1 ? data?.hours : '', [Validators.required]],
      minutes: [data?.minintes > -1 ? data?.minintes : '', [Validators.required]],
    });
    this.form.valueChanges.subscribe(() => {
      this.isAnyFieldFilled = this.checkIfAnyFieldFilled();
    });
    this.loading = false;
  }
  checkIfAnyFieldFilled(): boolean {
    const formValues = this.form.value;
    if (this.form.valid)
      for (const key in formValues) {
        if (formValues.hasOwnProperty(key) && formValues[key]) {
          return true;
        }
      }
    return false;
  }
  ngOnInit() {}
}
