import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginControllerService } from './../../../@core/api/notes-module/services/login-controller.service';
import { AuthenticationService, CredentialsService } from '@app/auth';
import { Router } from '@angular/router';
import { HotToastService } from '@ngneat/hot-toast';
import { MockAPIService } from '@shared';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { finalize, mergeMap, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-shared-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class SharedChangePasswordComponent implements OnInit {
  form!: FormGroup;
  isLoading: boolean;
  btnText = 'Submit';
  resetPassword: boolean;
  constructor(
    private apiService: MockAPIService,
    private toastService: HotToastService,
    private router: Router,
    private modalService: NgbModal,
    private credService: CredentialsService,
    private authenticationService: AuthenticationService,
    private formBuilder: FormBuilder,
    private loginControllerService: LoginControllerService
  ) {
    this.formInit();
  }
  ngOnInit(): void {}
  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);

      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  passwordConfirm(formGroup: FormGroup) {
    const { value: newPassword } = formGroup.get('newPassword');
    const { value: confirmPassword } = formGroup.get('confirmPassword');
    if (newPassword !== confirmPassword) {
      formGroup
        .get('confirmPassword')
        .setErrors({ ...formGroup.get('confirmPassword').errors, passwordNotMatch: true });
    } else {
      delete formGroup.get('confirmPassword')?.errors?.passwordNotMatch;
      if (this.isEmptyObject(formGroup.get('confirmPassword')?.errors)) {
        formGroup.get('confirmPassword').setErrors(null, { emitEvent: true });
      }
    }
    return null;
  }
  matchConfirm(type1: any, type2: any) {
    return (checkForm: FormGroup) => {
      let value1 = checkForm.controls[type1];
      let value2 = checkForm.controls[type2];
      if (value1.value === value2.value) {
        return value1.setErrors({ ...value1?.errors, notEquivalent: true });
      } else {
        delete value1?.errors?.notEquivalent;
        if (this.isEmptyObject(value1?.errors)) {
          value1.setErrors(null, { emitEvent: true });
        }
      }
    };
  }
  get newPasswordControl(): AbstractControl {
    return this.form.get('newPassword');
  }
  get currentPassword(): AbstractControl {
    return this.form.get('currentPassword');
  }
  get confirmPassword(): AbstractControl {
    return this.form.get('confirmPassword');
  }
  save() {
    if (this.form.valid) {
      this.isLoading = true;
      this.btnText = 'Checking Password';
      this.authenticationService
        .verifyPassword({
          userName: this.credService?.credentials?.userName,
          password: this.form.value?.currentPassword,
        })
        .pipe(
          switchMap((value: boolean) => {
            if (value) {
              this.btnText = 'Updating Password';
              return this.apiService.setPassword(this.form.value);
            } else {
              this.toastService.error(`Current password is incorrect!`);
              return of(false);
            }
          }),
          switchMap((value) => {
            if (this.resetPassword && value) {
              return this.loginControllerService.create({
                body: {
                  userId: this.credService?.credentials?.id,
                },
              });
            } else {
              return of(value);
            }
          }),
          finalize(() => {
            this.isLoading = false;
            this.btnText = 'Submit';
          }),
          untilDestroyed(this)
        )
        .subscribe((result: any) => {
          if (result) {
            if (result.status === -1) {
              this.toastService.error('Password update failed.');
            } else {
              if (result?.userId === this.credService?.credentials?.id) {
                this.modalService.dismissAll();
              }
              this.toastService.success('Password is updated.');
              this.authenticationService
                .logout()
                .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
            }
          }
        });
    } else {
      this.form.markAsTouched();
    }
  }
  private formInit(): void {
    this.form = this.formBuilder.group(
      {
        currentPassword: [undefined, [Validators.required]],
        newPassword: [
          undefined,
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(50),
            this.patternValidator(/\d/, { hasNumber: true }),
            this.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
            this.patternValidator(/[a-z]/, { hasSmallCase: true }),
            this.patternValidator(/[ -/:-@[-`{-~]/, { hasSpecialChar: true }),
          ],
        ],
        confirmPassword: [undefined, [Validators.required, Validators.minLength(8), Validators.maxLength(50)]],
      },
      {
        validators: [this.passwordConfirm.bind(this), this.matchConfirm('currentPassword', 'newPassword')],
      }
    );
  }
  private isEmptyObject(obj: any) {
    for (var prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
    }
    return true;
  }
}
