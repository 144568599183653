<nav [class]="showSide ? '' : 'squeeze'" [ngClass]="secUser ? 'switch-black' : 'switch-white'">
  <!-- Collapse 
  <a class="collapse-btn link" (click)="showSide = !showSide; activeIds = []">
    <i [ngClass]="{ fas: true, 'fa-chevron-left': showSide, 'fa-chevron-right': !showSide }"></i>
    <span *ngIf="showSide">Collapse</span>
  </a>
  <hr /> -->
  <div *ngIf="this.appDisconncted !== undefined">
  <!-- ============================ WORK =====================-->
  <a
      [ngClass]="{
        'pad-left': showSide,
        'flex-dir-row': !showSide,
        'disabled-link': appDisconncted,
        'list-text': !appDisconncted
      }"
      class="list-text"
      ngbPopover="Document(s) pending your action"
      placement="auto"
      triggers="mouseenter:mouseleave:click"
      popoverClass="clz-tooltip-popover"
    >
      <span
        class="navbar-user-initials iconspan"
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'INBOX' }"
        [ngClass]="view == 'INBOX' ? 'switch-color' : ''"
      >
        <i class="fas fa-tasks"></i>
      </span>
      <p
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'INBOX' }"
        *ngIf="!showSide"
        style="font-size: xx-small; cursor: pointer"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
      >
        WORK
      </p>
      <span
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'INBOX' }"
        class="expandlist"
        *ngIf="showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        >WORK</span
      >
    </a>

  <!-- ============================ ALL DOCUMENTS =====================-->
  <a
      [ngClass]="{
        'pad-left': showSide,
        'flex-dir-row': !showSide,
        'disabled-link': appDisconncted,
        'list-text': !appDisconncted
      }"
      ngbPopover="All documents"
      placement="auto"
      triggers="mouseenter:mouseleave:click"
      popoverClass="clz-tooltip-popover"
    >
      <span
        class="navbar-user-initials iconspan"
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'ALLDOCS' }"
        [ngClass]="view == 'ALLDOCS' ? 'switch-color' : ''"
      >
        <i class="fas fa-archive"></i>
      </span>
      <p
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'ALLDOCS' }"
        *ngIf="!showSide"
        style="font-size: xx-small; cursor: pointer"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
      >
        ALL FILES
      </p>
      <span
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'ALLDOCS' }"
        class="expandlist"
        *ngIf="showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        >ALL FILES</span
      >
    </a>

  <!-- ============================ INVOICE SETTINGS =====================-->
  <a
      class="list-text"
      *ngIf="isAdmin"
      [ngClass]="showSide ? 'pad-left' : 'flex-dir-row'"
      ngbPopover="Manage Invoice settings"
      placement="auto"
      triggers="mouseenter:mouseleave:click"
      popoverClass="clz-tooltip-popover"
    >
      <span
        class="navbar-user-initials iconspan"
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'FIELDS' }"
        [ngClass]="view == 'FIELDS' ? 'switch-color' : ''"
      >
        <i class="fa fa-wrench"></i>
      </span>
      <p
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'FIELDS' }"
        *ngIf="!showSide"
        style="font-size: xx-small; cursor: pointer; margin-left: 1px"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
      >
        INVOICE SETTINGS
      </p>
      <span
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'FIELDS' }"
        class="expandlist"
        *ngIf="showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        >INVOICE SETTINGS</span
      >
    </a>

  <!-- ============================ IMPORT SOURCES =====================-->
  <a *ngIf="isAdmin"
      class="list-text"
      [ngClass]="{
        'pad-left': showSide,
        'flex-dir-row': !showSide,
        'disabled-link': appDisconncted,
        'list-text': !appDisconncted
      }"
      ngbPopover="Configure and manage schedulers for Email polling and Export"
      placement="auto"
      triggers="mouseenter:mouseleave:click"
      popoverClass="clz-tooltip-popover"
    >
      <span
        class="navbar-user-initials iconspan"
        [routerLink]="['/schedulers']"
        [ngClass]="view == 'SCHEDULERS' ? 'switch-color' : ''"
      >
        <i class="fas fa-file-import" ></i>
      </span>
      <p
        [routerLink]="['/schedulers']"
        *ngIf="!showSide"
        style="font-size: xx-small; cursor: pointer"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
      >
        IMPORT SOURCE
      </p>
      <span
        [routerLink]="['/schedulers']"
        class="expandlist"
        *ngIf="showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        >IMPORT SOURCE</span
      >
    </a>
  <!-- ============================ EXPORT TARGET =====================-->
  <a *ngIf="isAdmin"
      class="list-text"
      [ngClass]="showSide ? 'pad-left' : 'flex-dir-row'"
      ngbPopover="Manage integrations with external applications like MYOB, XERO etc"
      placement="auto"
      triggers="mouseenter:mouseleave:click"
      popoverClass="clz-tooltip-popover"
    >
      <span
        class="navbar-user-initials iconspan"
        [routerLink]="['/external-app']"
        [ngClass]="view == 'EXT' ? 'switch-color' : ''"
      >
        <i class="fas fa-file-export" style="transform: translate(2.5px, -0.5px)"></i>
      </span>
      <p
        [routerLink]="['/external-app']"
        *ngIf="!showSide"
        style="font-size: xx-small; cursor: pointer"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
      >
        EXPORT TARGET
      </p>
      <span
        [routerLink]="['/external-app']"
        class="expandlist"
        *ngIf="showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        >EXPORT TARGET</span
      >
    </a>

  <!-- ============================ BRANDING (currently hidden) =====================-->
  <a *ngIf="false"
      class="list-text"
      [ngClass]="showSide ? 'pad-left' : 'flex-dir-row'"
      ngbPopover="Manage Logo & Banner Settings"
      placement="auto"
      triggers="mouseenter:mouseleave:click"
      popoverClass="clz-tooltip-popover"
    >
      <span
        class="navbar-user-initials iconspan"
        [routerLink]="'/settings'"
        routerLinkActive="active"
        [ngClass]="view == 'SETTINGS' ? 'switch-color' : ''"
      >
        <i class="fas fa-cog"></i>
      </span>
      <span
        routerLink="/settings"
        routerLinkActive="active"
        *ngIf="showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        class="expandlist"
        >SETTINGS</span
      >
      <p
        routerLink="/settings"
        routerLinkActive="active"
        *ngIf="!showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        style="font-size: xx-small; cursor: pointer"
      >
        SETTINGS
      </p>
    </a>

  <!-- ============================ USERS =====================-->
  <hr *ngIf="isAdmin" />

    <a
      class="list-text"
      *ngIf="isAdmin"
      [ngClass]="showSide ? 'pad-left' : 'flex-dir-row'"
      ngbPopover="Manage users in your organization"
      placement="auto"
      triggers="mouseenter:mouseleave:click"
      popoverClass="clz-tooltip-popover"
    >
      <span
        class="navbar-user-initials iconspan"
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'USERS' }"
        [ngClass]="view == 'USERS' ? 'switch-color' : ''"
      >
        <i class="fas fa-envelope-open"></i>
      </span>
      <p
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'USERS' }"
        *ngIf="!showSide"
        style="font-size: xx-small; cursor: pointer"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
      >
        USERS
      </p>
      <span
        [routerLink]="['/dashboard']"
        [queryParams]="{ view: 'USERS' }"
        class="expandlist"
        *ngIf="showSide"
        [ngClass]="secUser ? 'switch-black' : 'switch-white'"
        >USERS</span
      >
    </a>
  </div>

  <!-- Modules -->

  <span class="role d-block font-weight-light">
    <span *ngIf="showSide">App Version: </span>
    <span *ngIf="!showSide">v </span>
    {{ version }}</span
  >
</nav>
