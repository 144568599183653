import { RouterInfo } from './../../../@shared/interface/route.interface';
import { HelperService } from './../../../@shared/services/helper.service';
import { environment } from '@env/environment';
import { ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, Credentials, CredentialsService } from '@app/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DOCUMENT } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MockAPIService, User } from '@app/@shared';
import { Observable } from 'rxjs';
import { Memberships } from '@app/@shared/interface/memberships.interface';
import { Input } from '@angular/core';
@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isDisabled: boolean = true;
  views: any;
  set show(show: boolean) {
    this.showSide.emit(show);
    this._showSide = show;
  }
  get show(): boolean {
    return this._showSide;
  }
  @Input() set search(show: any) {}

  @Output() showSide = new EventEmitter<boolean>();
  @Output() actionEvent = new EventEmitter<any>();
  private _showSide: boolean = false;
  private _search: boolean = false;
  menuHidden = true;
  currentRoute: RouterInfo[];
  secUser: Boolean;
  switchAsRoleOrUser: string = '';
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    public activatedRoute: ActivatedRoute,
    private helperService: HelperService,
    private modalService: NgbModal,
    private apiService: MockAPIService,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document,
    public changeRef: ChangeDetectorRef
  ) {
    // subs to helper Route Information Service
  }

  formatName(str) {
    // return str.replaceAll('%20',' ');
    var map = {
      INBOX: 'Work',
      ALLDOCS: 'All Documents',
      REQUESTS: 'All Documents',
      FIELDS: 'Applications',
      DATASETS: 'Datasets',
      SCHEDULER: 'Schedulers',
      SCHEDULERS: 'Schedulers',
      USERS: 'Manage Users',
    };
    if (map[str]) str = map[str];
    return decodeURIComponent(str);
  }
  ngOnInit() {
    this.helperService.routerInfo.pipe(untilDestroyed(this)).subscribe((route) => {
      if (route == null) {
        this.route.queryParams.subscribe((params) => {
          let list: RouterInfo[] = [{ route: params.view }];
          this.currentRoute = list;
        });
      } else {
        this.currentRoute = route;

        this.changeRef.detectChanges();
      }
    });

    this.credentialsService.secCredentials != null ? (this.secUser = true) : (this.secUser = false);
    if (this.credentialsService.switchas != null) {
      this.switchAsRoleOrUser = this.credentialsService.switchas;
    }
    this.document.body.addEventListener('click', (event: any) => {
      let classArray = [];
      event.target.classList.forEach((val) => {
        classArray.push(val);
      });
      if (
        classArray.filter(
          (className) => className === 'navbar' || className.includes('navbar') || className.includes('nav')
        )?.length === 0
      ) {
        this.menuHidden = true;
      }
    });
    this.authenticationService.getUserProfile().subscribe();
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }
  logout() {
    var orgId = this.credentialsService.orgId;
    this.authenticationService.logout().subscribe(() => this.router.navigateByUrl(`/login?c=${orgId}`));
  }

  get userName(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? credentials.userName : null;
  }
  get name(): string | null {
    const credentials = this.credentialsService.credentials;
    return credentials ? `${credentials.firstName ?? ''} ${credentials.lastName ?? ''}` : null;
  }
  get jobTitle(): string | null {
    const credentials = this.credentialsService.credentials;
    return true || this.switchAsRoleOrUser == 'Role' ? credentials?.memberships[0].roleName : credentials?.jobTitle; // !!! hard locked
  }
  get user(): Credentials | null {
    return this.credentialsService.credentials;
  }
  get AvatarUrl(): string {
    return environment.serverUrl + '';
  }
  get isAdmin(): boolean {
    return this.credentialsService.isAdmin;
  }
  get customInfo(): any {
    return this.credentialsService?.credentials?.customInfo;
  }
  get logoURL(): string {
    return this.credentialsService?.credentials?.logo;
  }
  addToRoute(name: string, link: string) {
    this.helperService.addRouterInfo([{ route: name, link: link }]);
  }
}
