import { env } from './.env';
export const environment = {
  production: true,
  label: 'Sandbox',
  version: require('../../package.json').version,
  serverUrl: 'https://staging.in2linx.com.au/bonita/',
  bpmUrl: 'https://staging.in2linx.com.au/',
  notesAPIUrl: 'https://staging.in2linx.com.au/api/',
  notesAPIUrl2: 'https://staging.in2linx.com.au/api/',
  webUrl: 'https://staging.in2linx.com.au/app',
  documentUrl: 'https://staging.in2linx.com.au/bonita/portal/',
  mailbox: ``,
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  siteKey: '6Lew0LIlAAAAANXsI2RETWXaFxvd656z1RMuATBy',
};
