import { MockAuthenticationService } from './authentication.service.mock';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

import { environment } from '@env/environment';
import { SharedFormErrorComponent } from './form-error/form-error.component';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ReactiveFormsModule,
    NgbModule,
    AuthRoutingModule,
  ],
  declarations: [LoginComponent, SharedFormErrorComponent],
  providers: [
    MockAuthenticationService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.siteKey } as RecaptchaSettings,
    },
  ],
})
export class AuthModule {}
