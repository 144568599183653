import { RouterInfo } from './../interface/route.interface';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
export class HelperService {
  routerInfo = new BehaviorSubject<RouterInfo[]>(undefined);
  private urlHistory: string[] = [];

  constructor(private router: Router) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.urlHistory.push(event.url);
        if (this.urlHistory.length > 2) {
          this.urlHistory.shift();
        }
      }
    });
  }

  public getPreviousUrl() {
    if (this.urlHistory.length) {
      return this.urlHistory[0];
    }
    return '';
  }

  addRouterInfo(route: RouterInfo[]) {
    this.routerInfo.next(route);
  }
}
