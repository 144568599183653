<div class="set-url-modal">
  <div class="heading d-flex align-items-center justify-content-between">
    <span>{{ title }}</span>
    <i class="fas fa-times" (click)="activeModal.dismiss()"></i>
  </div>

  <div class="set-url-modal-content">
    <div class="buttons-container d-flex align-items-center">
      {{ message }}
    </div>

    <div class="buttons-container d-flex align-items-center justify-content-end">
      <a *ngIf="closeButtons" style="padding: 7px" class="link" (click)="activeModal.dismiss()">{{
        closeButtons ?? 'Cancel'
      }}</a>
      <button *ngIf="successButtons" class="btn btn-primary" (click)="onSubmit()">
        {{ successButtons ?? 'Ok' }}
      </button>
    </div>
  </div>
</div>
