import { UntilDestroy } from '@ngneat/until-destroy';
import { FormArray, FormGroup } from '@angular/forms';

@UntilDestroy()
export class FormService {
  error: string;
  form!: FormGroup;
  isLoading: boolean;
  disabled: boolean;
  isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }
  trackByFuntion(index: any, item: any) {
    return item?.value?.id ?? item?.id ?? index?.id;
  }
  async updateandValidate(form: FormGroup | FormArray, data: any | any[], initiateForm?: FormGroup) {
    if (data && Array.isArray(data) && Array.isArray(form?.controls)) {
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        form.controls[index] = await this.assignValuestoForm(element, form.controls[index] as FormGroup, initiateForm);
      }
    } else {
      form = await this.assignValuestoForm(data, form as FormGroup, initiateForm);
    }
    form.updateValueAndValidity();
    return form;
  }

  protected removeNullObjects(data?: any) {
    const newdata = data;
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property) && ((data as any)[property] == null || (data as any)[property] === '')) {
          delete newdata[property];
        } else if (typeof (data as any)[property] === 'object') {
          const childData = (data as any)[property];

          for (const childProperty in childData) {
            if (
              childData.hasOwnProperty(childProperty) &&
              ((childData as any)[childProperty] == null || (childData as any)[childProperty] === '')
            ) {
              delete newdata[property][childProperty];
            }
          }
        }
      }
    }
    return newdata;
  }
  private async assignValuestoForm(data: any, form: FormGroup, initiateForm?: FormGroup): Promise<FormGroup> {
    const _form: FormGroup = form ?? initiateForm;
    for (const property in data) {
      if (data.hasOwnProperty(property) && _form.get(property) && (data as any)[property] != null) {
        if (typeof (data as any)[property] === 'boolean') {
          _form.get(property)?.setValue((data as any)[property]);
        } else if (this.isDate((data as any)[property])) {
          const _date = new Date((data as any)[property])?.toISOString();
          _form.get(property)?.setValue(_date?.split('T')[0]);
        } else {
          if (typeof (data as any)[property] === 'object') {
            // child object loop
            const childData = (data as any)[property];
            let isStringArray: boolean;
            if (Array.isArray(childData)) {
              isStringArray = childData?.some((value: any) => {
                return typeof value === 'string';
              });
            }
            if (isStringArray) {
              _form.get(property)?.setValue((data as any)[property]);
            } else {
              for (const childProperty in childData) {
                if (
                  childData.hasOwnProperty(childProperty) &&
                  _form.get(`${property}.${childProperty}`) &&
                  (childData as any)[childProperty] != null
                ) {
                  if (typeof (childData as any)[childProperty] === 'object') {
                  } else {
                    if (typeof (childData as any)[childProperty] === 'boolean') {
                      _form.get(`${property}.${childProperty}`)?.setValue((childData as any)[childProperty]);
                    } else {
                      _form.get(`${property}.${childProperty}`)?.setValue((childData as any)[childProperty]);
                    }
                  }
                }
              }
            }
            // end
          } else {
            _form.get(property)?.setValue((data as any)[property]);
          }
        }
      }
    }

    return _form;
  }
  private isDate(_date: string) {
    const _regExp = new RegExp(
      '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$'
    );
    return _regExp.test(_date);
  }
}
